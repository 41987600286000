import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { CustomInput } from "src/components";
import { getAdminRoles, inviteAdmin } from "src/services/api";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Button } from "src/components/ui/button";
import { AddIcon, SendIcon, TrashIcon } from "src/assets/svg";
import { removeItemAtIndex } from "src/utils/functions";
import * as Yup from "yup";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { useToast } from "src/components/ui/use-toast";
import { useModalContext } from "src/components/Modal";

const EMPTY_ADMIN = { email: "", roleId: "" };

const InviteAdmin = () => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { setIsOpen } = useModalContext();
	const { toast } = useToast();

	const initialValues = {
		admins: [{ email: "", roleId: "" }],
	};
	const validationSchema = Yup.object().shape({
		admins: Yup.array()
			.min(1, "At least one admin must be invited")
			.of(
				Yup.object().shape({
					email: Yup.string().email("Please provide a valid email").required("This field is required"),
					roleId: Yup.number().integer().required("This field is required"),
				})
			),
	});

	const { data } = useQuery({
		queryKey: ["adminRoles"],
		queryFn: getAdminRoles,
	});
	const roles = data?.data;

	const { mutate: inviteMutate } = useMutation(inviteAdmin, {
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			setIsOpen(false);
			queryClient.refetchQueries({ queryKey: ["admins"] });
		},
		onError: (error) => {
			if (error?.cause === 402) {
				setIsOpen(false);
				setIsPasswordModalOpen(true);
			}
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => inviteMutate(values)}>
			{({ values, setFieldValue, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-10">
					<div className="flex flex-col border-b border-gray-200 pb-5 gap-5 min-h-[250px]">
						{values.admins.map((admin, index) => (
							<div className="grid grid-cols-[6fr,4fr,1fr] h-fit gap-4">
								<CustomInput
									value={admin.email}
									name={`admins[${index}].email`}
									onChange={handleChange}
									placeholder="Email address"
								/>
								<div>
									<Select onValueChange={(value) => setFieldValue(`admins[${index}].roleId`, value)} defaultValue={values.role}>
										<SelectTrigger className="py-4 text-gray-500 h-fit bg-gray-200 !font-normal text-[1.6rem]">
											<SelectValue placeholder="Select a role" />
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												<SelectLabel>Roles</SelectLabel>
												{roles?.map(({ id, name }, index) => (
													<SelectItem value={String(id)} key={index}>
														{name}
													</SelectItem>
												))}
											</SelectGroup>
										</SelectContent>
									</Select>
									<ErrorMessage name={`admins[${index}].roleId`} component="div" className="text-red-500 text-[1.2rem] mt-1" />
								</div>
								<Button
									type="button"
									className="px-2 py-5 bg-gray-200 rounded-lg h-fit hover:bg-gray-200 hover:text-gray-500"
									onClick={() => setFieldValue("admins", removeItemAtIndex(values.admins, index))}
								>
									<TrashIcon className="w-[20px] h-[20px] text-gray-500" />
								</Button>
							</div>
						))}
						<Button
							className="flex items-center gap-4 mt-2 p-2 h-fit w-fit bg-gray-200 rounded-lg text-[1.4rem] text-gray-500 px-6 py-4 hover:bg-gray-200 hover:text-gray-500"
							onClick={() => setFieldValue("admins", [...values.admins, { ...EMPTY_ADMIN }])}
						>
							Add <AddIcon className="w-[20px] h-[20px] text-gray-500" />
						</Button>
					</div>
					<Button className="flex items-center gap-4 rounded-lg text-[1.4rem] px-6 py-5" onClick={() => {}}>
						Send Invite <SendIcon className="w-[20px] h-[20px]" />
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default InviteAdmin;
