import { ErrorMessage } from "formik";
import React from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";

const CustomSelect = ({ label, name, containerClassName, options, placeholder, ...props }) => {
	return (
		<div className={containerClassName}>
			{label && (
				<label htmlFor={name} className="block text-[1.4rem] font-medium text-gray mb-1">
					{label}
				</label>
			)}
			<Select {...props}>
				<SelectTrigger className="text-gray-800">
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						{options?.map(({ value, label }, index) => (
							<SelectItem value={String(value)} key={index}>
								{label}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
			{name && <ErrorMessage name={name} component="div" className="text-red-500 text-[1.2rem] mt-1" />}
		</div>
	);
};

export default CustomSelect;
