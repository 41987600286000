import React, { forwardRef } from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import { Loader, Pagination } from ".";

const CustomDataTable = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div className="table-responsive mb-[70px]">
			<DataTable
				pagination
				progressComponent={<Loader />}
				paginationServer
				paginationComponent={Pagination}
				paginationDefaultPage={searchParams.has("page") ? parseInt(searchParams.get("page")) : 1}
				paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100, 200]}
				paginationPerPage={searchParams.has("per_page") ? parseInt(searchParams.get("per_page")) : 50}
				onChangePage={(page) => {
					if (page === 1) {
						searchParams.delete("page");
						setSearchParams(searchParams);
					}
				}}
				customStyles={{
					table: {
						style: {
							border: props.data?.length ? "1px solid #F3F4F6" : "none",
							borderRadius: 8,
							paddingInline: "0",
						},
					},
					rows: {
						style: {
							fontSize: "1.4rem",
							fontWeight: 400,
							color: "#374151",
							letterSpacing: "-1%",
							paddingBlock: "1.4rem",
							borderBottom: "1px solid #F3F4F6 !important",
							"&>div:first-of-type": { paddingLeft: "1.5rem" },
							"&>div:last-of-type": { paddingRight: "1.5rem" },
						},
					},
					headRow: {
						style: {
							fontSize: "1.4rem",
							fontWeight: 600,
							letterSpacing: "-1%",
							color: "#6D7280",
							backgroundColor: "#F3F4F6",
							paddingBlock: "1rem",
							"&>div:first-of-type": { paddingLeft: "1.5rem" },
							"&>div:last-of-type": { paddingRight: "1.5rem" },
						},
					},
					subHeader: {
						style: {
							paddingInline: 0,
							paddingBlock: "1.5rem",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
						},
					},
				}}
				selectableRowsComponent={CheckBox}
				{...props}
			/>
		</div>
	);
};

const CheckBox = forwardRef((props, ref) => {
	return (
		<input
			ref={ref}
			type="checkbox"
			className="w-[14px] h-[15px] border-2 border-[#DAE0E6] cursor-pointer rounded-lg accent-[#F97316] mr-4"
			{...props}
		/>
	);
});

export default CustomDataTable;
