import React, { useEffect } from "react";
import { Formik } from "formik";
import { CustomInput } from "src/components";
import * as yup from "yup";
import { BannerComponent } from "./layout/AuthLayout";
import { PiCheck } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "src/services/AuthAPI";
import { renderAlert } from "src/utils/functions";

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const navigate = useNavigate();
	const initialValues = { password: "", confirmPassword: "" };

	const { mutate, isLoading } = useMutation(resetPassword, {
		onSuccess: (res) => {
			renderAlert({
				title: "Success",
				msg: res?.message,
				type: "success",
			}).then(() => {
				navigate("/auth");
			});
		},
	});

	const validationSchema = yup.object({
		password: yup
			.string()
			.required("Password is required")
			.min(8, "Password must be at least 8 characters")
			.matches(/[A-Z]/, "Password must contain at least one uppercase letter")
			.matches(/\d/, "Password must contain at least one number"),
		confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
	});

	useEffect(() => {
		if (!token) {
			navigate("/auth");
		}
	}, [navigate, searchParams, token]);

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 shadow-[0_0px_24px_-4px_rgba(16,24,40,0.08)]  gap-[3rem] min-h-[50rem]">
			<BannerComponent />
			<div className="p-[5rem] flex flex-col justify-center  w-[45rem] md:w-auto">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={({ confirmPassword, ...values }) => {
						mutate({ password: values?.password, token: token });
					}}
				>
					{({ values, handleChange, handleBlur, handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<div className="mb-[3rem]">
								<p className="text-[2.4rem] font-bold text-primary mb-3">Reset Password</p>
								<p className="text-[1.4rem] font-normal text-gray">Please enter a secure password</p>
							</div>
							<div className="mb-[3rem]">
								<CustomInput
									type="password"
									value={values.password}
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									placeholder="Password"
								/>
							</div>
							<div className="mb-[3rem]">
								<CustomInput
									type="password"
									value={values.confirmPassword}
									name="confirmPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									placeholder="Confirm password"
								/>
							</div>

							<div className="text-[1.3rem] text-normal">
								<p
									className={`mb-2 flex items-center gap-[1rem] ${
										values.password.length >= 8 ? "text-[#16A34A]" : "text-[#4B5563]"
									}`}
								>
									{values.password.length >= 8 ? (
										<PiCheck className="text-[1.4rem]" />
									) : (
										<AiOutlineClose className="text-[1.4rem]" />
									)}
									At least 8 characters
								</p>
								<p
									className={`mb-2 flex items-center gap-[1rem] ${
										/[A-Z]/.test(values.password) ? "text-[#16A34A]" : "text-[#4B5563]"
									}`}
								>
									{/[A-Z]/.test(values.password) ? (
										<PiCheck className="text-[1.4rem]" />
									) : (
										<AiOutlineClose className="text-[1.4rem]" />
									)}
									At least 1 uppercase character
								</p>
								<p
									className={`mb-2 flex items-center gap-[1rem] ${
										/\d/.test(values.password) ? "text-[#16A34A]" : "text-[#4B5563]"
									}`}
								>
									{/\d/.test(values.password) ? (
										<PiCheck className="text-[1.4rem]" />
									) : (
										<AiOutlineClose className="text-[1.4rem]" />
									)}
									At least 1 number
								</p>
							</div>

							<div className="flex justify-between gap-[2rem] mt-[5rem]">
								<button
									type="submit"
									className="btn-loader bg-primary w-full text-white rounded-[6px] h-[4.4rem] py-[.8rem] px-[2rem]"
									disabled={isLoading}
								>
									Submit
								</button>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default ResetPassword;
