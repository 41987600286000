import React from "react";

const CustomCheckbox = ({ label, labelClassName, type = "checkbox", ...rest }) => {
	return (
		<div className={`flex gap-2 items-center`}>
			<input
				type={type}
				id={label}
				className={`border w-[16px] h-[16px] border-[#f97316] !rounded-md p-[10px] accent-[#f97316] text-[1.2rem] ${rest.className}`}
				{...rest}
			/>
			<label htmlFor={label} className={`text-[1.6rem] text-black font-medium ${labelClassName}`}>
				{label}
			</label>
		</div>
	);
};

export default CustomCheckbox;
