import { useCallback } from "react";
import {
	AlignCenter,
	AlignLeft,
	AlignRight,
	Bold,
	Heading1,
	Highlighter,
	Italic,
	Link2,
	List,
	ListOrderedIcon,
	Minus,
	Pilcrow,
	Redo,
	Strikethrough,
	Underline,
	Undo,
	WrapText,
} from "lucide-react";
import { Toggle } from "./ui/toggle";

function Toolbar({ editor }) {
	if (!editor) {
		return null;
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const setLink = useCallback(() => {
		const previousUrl = editor.getAttributes("link").href;
		const url = window.prompt("URL", previousUrl);

		// cancelled
		if (url === null) {
			return;
		}

		if (url === "") {
			editor.chain().focus().extendMarkRange("link").unsetLink().run();
			return;
		}
		editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
	}, [editor]);

	return (
		<div className="flex items-center gap-2 px-4 py-2 mb-2 overflow-x-scroll border-b rounded-sm bg-neutral-50/50 scrollbar">
			<Toggle pressed={editor.isActive("bold")} onPressedChange={() => editor.chain().focus().toggleBold().run()} size={"sm"}>
				<Bold className="w-6 h-6" />
			</Toggle>
			<Toggle
				pressed={editor.isActive("heading", { level: 1 })}
				onPressedChange={() => {
					editor.chain().focus().toggleHeading({ level: 1 }).run();
				}}
				size="sm"
			>
				<Heading1 className="w-6 h-6" />
			</Toggle>

			<Toggle
				pressed={editor.isActive("italic")}
				onPressedChange={() => editor.chain().focus().toggleItalic().run()}
				size="sm"
				aria-label="Toggle italic"
			>
				<Italic className="w-6 h-6" />
			</Toggle>
			<Toggle pressed={editor.isActive("underline")} onPressedChange={() => editor.chain().focus().toggleUnderline().run()} size="sm">
				<Underline className="w-6 h-6" />
			</Toggle>
			<Toggle onPressedChange={() => editor.chain().focus().toggleHighlight().run()} pressed={editor.isActive("highlight")}>
				<Highlighter className="w-6 h-6" />
			</Toggle>
			<Toggle
				pressed={editor.isActive("strike")}
				onPressedChange={() => editor.chain().focus().toggleStrike().run()}
				disabled={!editor.can().chain().focus().toggleStrike().run()}
				size="sm"
			>
				<Strikethrough className="w-6 h-6" />
			</Toggle>
			<Toggle
				size="sm"
				onPressedChange={() => editor.chain().focus().setTextAlign("left").run()}
				pressed={editor.isActive({ textAlign: "left" })}
			>
				<AlignLeft className="w-6 h-6" />
			</Toggle>
			<Toggle
				size="sm"
				onPressedChange={() => editor.chain().focus().setTextAlign("center").run()}
				pressed={editor.isActive({ textAlign: "center" })}
			>
				<AlignCenter className="w-6 h-6" />
			</Toggle>
			<Toggle
				size="sm"
				onPressedChange={() => editor.chain().focus().setTextAlign("right").run()}
				pressed={editor.isActive({ textAlign: "right" })}
			>
				<AlignRight className="w-6 h-6" />
			</Toggle>
			<Toggle size="sm" onPressedChange={() => editor.chain().focus().toggleOrderedList().run()} pressed={editor.isActive("orderedList")}>
				<ListOrderedIcon className="w-6 h-6" />
			</Toggle>
			<Toggle size="sm" onPressedChange={() => editor.chain().focus().toggleBulletList().run()} pressed={editor.isActive("bulletList")}>
				<List className="w-6 h-6" />
			</Toggle>

			<Toggle size="sm" onPressedChange={() => editor.chain().focus().setParagraph().run()} pressed={editor.isActive("paragraph")}>
				<Pilcrow className="w-6 h-6" />
			</Toggle>
			<Toggle pressed={editor.isActive("heading", { level: 3 })} onPressedChange={setLink} size="sm">
				<Link2 className="w-6 h-6" />
			</Toggle>
			<Toggle size="sm" onPressedChange={() => editor.chain().focus().setHorizontalRule().run()} pressed={false}>
				<Minus className="w-6 h-6" />
			</Toggle>
			<Toggle size="sm" onPressedChange={() => editor.chain().focus().setHardBreak().run()} pressed={false}>
				<WrapText className="w-6 h-6" />
			</Toggle>
			<Toggle size="sm" onPressedChange={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()}>
				<Undo className="w-6 h-6" />
			</Toggle>
			<Toggle size="sm" onPressedChange={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()}>
				<Redo className="w-6 h-6" />
			</Toggle>
		</div>
	);
}

export default Toolbar;
