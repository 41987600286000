import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import React from "react";
import { CustomCheckbox, CustomInput } from "src/components";
import { useModalContext } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { useToast } from "src/components/ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { createAdminRoles } from "src/services/api";
import * as Yup from "yup";

const CreateRole = () => {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const { setIsOpen } = useModalContext();
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();

	const initialValues = {
		name: "",
		permissions: [],
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		permissions: Yup.array().of(Yup.string().required()).min(1, "At least one permission is required for a role"),
	});

	const { mutate, isLoading } = useMutation(createAdminRoles, {
		onSuccess: ({ message }) => {
			queryClient.refetchQueries({ queryKey: ["adminRoles"] });
			setIsOpen(false);
			toast({ title: "Success", description: message });
		},
		onError: (error) => {
			if (error?.cause === 402) {
				setIsOpen(false);
				setIsPasswordModalOpen(true);
			}
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleSubmit, handleChange }) => (
				<form onSubmit={handleSubmit} className="grid gap-8">
					<CustomInput
						value={values.name}
						name={`name`}
						containerClassName={"max-w-[300px]"}
						onChange={handleChange}
						placeholder="Role name"
					/>
					<div className="grid gap-6 pb-8 border-b border-gray-200">
						<p className="uppercase text-[1.4rem] font-medium text-[#9CA3AF]">Permissions</p>
						<PermissionSection
							name="Admin management"
							permissions={[
								{ name: "View admin", value: "admin:view" },
								{ name: "Invite admin", value: "admin:invite" },
								{ name: "Manage admin", value: "admin:manage" },
								{ name: "Create role", value: "create:role" },
							]}
						/>
						<PermissionSection
							name="Client management"
							permissions={[
								{ name: "View clients", value: "client:view" },
								{ name: "Manage clients", value: "client:manage" },
							]}
						/>
						<PermissionSection
							name="Subscription management"
							permissions={[
								{ name: "View plans", value: "subscription:view" },
								{ name: "Manage plans", value: "subscription:manage" },
								{ name: "Create role", value: "role:create" },
							]}
						/>
						<PermissionSection
							name="Orders"
							permissions={[
								{ name: "View orders", value: "order:view" },
								{ name: "View transactions", value: "transaction:view" },
							]}
						/>
						<PermissionSection
							name="Configuration"
							permissions={[
								{ name: "View", value: "config:view" },
								{ name: "Manage", value: "config:manage" },
							]}
						/>
						<ErrorMessage name="permissions" component="div" className="text-red-500 text-[1.2rem] mt-1" />
					</div>
					<Button className="flex items-center gap-4 rounded-lg text-[1.4rem] px-6 py-5" disabled={isLoading}>
						Create Role
					</Button>
				</form>
			)}
		</Formik>
	);
};

const PermissionSection = ({ name, permissions }) => {
	const { values, handleChange } = useFormikContext();

	return (
		<div className="grid grid-cols-[180px,1fr] gap-x-10 gap-y-5">
			<p className="text-black text-[1.4rem] font-medium">{name}</p>
			<div className="grid grid-cols-[repeat(auto-fill,minmax(135px,1fr))] gap-6">
				{permissions?.map(({ name, value }, index) => (
					<CustomCheckbox
						label={name}
						value={value}
						labelClassName="!text-[1.4rem] !font-normal"
						name="permissions"
						type="checkbox"
						key={index}
						checked={values.permissions.includes(value)}
						onChange={handleChange}
					/>
				))}
			</div>
		</div>
	);
};

export default CreateRole;
