import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FilterIcon, NoDataIcon, PaystackIcon, RightArrowHeadIcon, SearchIcon, StripeIcon } from "src/assets/svg";
import { CustomCheckbox, CustomDataTable, CustomInput, EmptyState, InfoColumn, InfoRow, ItemInfo, StatCard } from "src/components";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "src/components/ui/accordion";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "src/components/ui/sheet";
import { getOrderAnalytics, getOrders, getTransactions } from "src/services/api";
import { COUNTRIES_MAP } from "src/utils/constants";
import { renderCurrency, renderNumber } from "src/utils/functions";

const orderStatusGenerator = (status) => {
	switch (status) {
		case "CHECKOUT":
			return "text-orange-500 bg-orange-100";
		case "PLACED":
			return "text-blue-500 bg-blue-100";
		case "READY":
			return "text-gray-800 bg-gray-50";
		case "SHIPPED":
			return "text-green-500 bg-green-100";
		case "INTRANSIT":
			return "text-[#EEA23E] bg-[#FFF8EB]";
		case "DELIVERED":
			return "text-[#574EFA] bg-[#ECEBFF]";
		case "DELETED":
			return "text-red-500 bg-red-100";
		default:
			return "text-[#22c55e] bg-[#f0fdf4]";
	}
};
const transactionStatusGenerator = (status) => {
	switch (status) {
		case "paid out":
			return "text-[#22c55e] bg-[#f0fdf4]";
		case "pending":
			return "text-[#eea23e] bg-[#fff8eb]";
		case "failed":
			return "text-[#e2341d] bg-[#fff2f0]";
		case "success":
			return "text-[#437ef7] bg-[#f5faff]";
		default:
			return "";
	}
};
const returnPaymentProcessorIcon = (processor) => {
	switch (processor) {
		case "paystack":
			return <PaystackIcon />;
		case "stripe":
			return <StripeIcon />;
		default:
			return null;
	}
};
const statuses = ["DELETED", "CHECKOUT", "PLACED", "READY", "SHIPPED", "INTRANSIT", "DELIVERED"];

const tabs = { orders: 0, transactions: 1 };

const Orders = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState([]);
	const [transactionStatus, setTransactionStatus] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [paymentProcessor, setPaymentProcessor] = useState("");

	const { data } = useQuery({
		queryKey: ["order-analytics"],
		queryFn: getOrderAnalytics,
		suspense: true,
	});

	const analytics = data?.data;

	return (
		<div>
			<div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-6 mb-10">
				<StatCard title="Order Count" amount={renderNumber(analytics?.totalCount)} />
				<StatCard title="TOTAL SALES" amount={renderNumber(analytics?.totalSales ?? 0)} />
				<StatCard
					title="LOCAL VS INT’L ORDERS"
					amount={`${renderNumber(analytics?.localCount)} vs ${renderNumber(analytics?.internationalCount)}`}
				/>
			</div>
			<Tabs
				defaultIndex={searchParams.has("tab") ? tabs[searchParams.get("tab")] : 0}
				onSelect={(_, __, event) => setSearchParams({ tab: event.target.dataset.id })}
			>
				<div className="flex items-center justify-between">
					<TabList>
						<Tab data-id="orders">All Orders</Tab>
						<Tab data-id="transactions">Transactions</Tab>
					</TabList>
					<RenderActions
						{...{
							search,
							setSearch,
							status,
							setStatus,
							startDate,
							setStartDate,
							endDate,
							setEndDate,
							paymentProcessor,
							setPaymentProcessor,
							transactionStatus,
							setTransactionStatus,
						}}
					/>
				</div>

				<TabPanel>
					<AllOrders {...{ search, status, startDate, endDate }} />
				</TabPanel>
				<TabPanel>
					<Transactions {...{ search, transactionStatus, startDate, endDate, paymentProcessor }} />
				</TabPanel>
			</Tabs>
		</div>
	);
};

const AllOrders = ({ search, status, startDate, endDate }) => {
	const [searchParams] = useSearchParams();
	const page = searchParams.get("page") ?? 1;

	const columns = [
		{
			name: "Order ID",
			cell: (row) => <div className="px-3 py-2 font-semibold bg-gray-100 rounded-lg">Order #{row.id}</div>,
		},
		{ name: "Customer", selector: (row) => `${row?.meta?.firstName} ${row?.meta?.lastName}`, style: { textTransform: "capitalize" } },
		{ name: "Business", selector: (row) => row?.business?.name, style: { textTransform: "capitalize" } },
		{ name: "Date", selector: (row) => format(new Date(row.createdAt), "dd MMM yyyy"), maxWidth: "160px", minWidth: "150px" },
		{
			name: "Status",
			cell: (row) => (
				<div className={`px-4 py-2 font-medium rounded-lg min-w-fit ${orderStatusGenerator(row.status.toUpperCase())}`}>{row.status}</div>
			),
			maxWidth: "160px",
			minWidth: "140px",
		},
		{ name: "Items", selector: (row) => row.variantsonOrder?.reduce((sum, currentValue) => sum + currentValue.quantity, 0), maxWidth: "110px" },
		{
			name: "",
			cell: (row) => {
				const total = row.variantsonOrder?.reduce((sum, currentValue) => sum + currentValue.quantity, 0);
				const orderType = row?.country === COUNTRIES_MAP[row?.business?.info?.country] ? "Local" : "International";

				return (
					<Sheet>
						<SheetTrigger asChild>
							<Button className="flex items-center hover:bg-transparent bg-transparent border-0 gap-2 text-[#050505] font-medium text-[1.6rem]">
								View <RightArrowHeadIcon />
							</Button>
						</SheetTrigger>
						<SheetContent className="sm:w-[550px] sm:max-w-[550px]">
							<SheetHeader className="">
								<SheetTitle className="">Order Details</SheetTitle>
							</SheetHeader>
							<div className="grid gap-6 h-[calc(100%-50px)] pb-14 overflow-y-scroll">
								<div>
									<p className="text-[#1F2937] text-[1.6rem] font-medium">{row?.business?.name}</p>
									<p className="text-[#6D7280] text-[1.4rem]">Order #{row?.id}</p>
								</div>
								<div className="grid grid-cols-[repeat(auto-fit,minmax(120px,1fr))] [&>div:not(:last-of-type)]:border-r [&>div:not(:last-of-type)]:border-lightGray gap-6 border rounded-lg border-lightGray p-8">
									<InfoColumn title="Total Price" info={renderCurrency(row?.price + row?.shippingFees)} />
									<InfoColumn title="Date Purchased" info={format(new Date(row.createdAt), "dd MMM yyyy")} />
									<InfoColumn title="Delivery type" info={orderType} />
								</div>
								<p className="text-darkBlue text-[1.4rem] font-semibold mt-4">Customer Details</p>
								<div className="grid gap-4">
									<InfoRow title="Email address" info={row?.customer?.email} />
									<InfoRow title="Phone number" info={`${row?.meta?.phoneNumberPrefix} ${row?.meta?.phoneNumber}`} />
									<InfoRow title="Shipping address" info={`${row?.address}, ${row?.city}, ${row?.state}, ${row?.country}`} />
								</div>
								<p className="text-darkBlue text-[1.4rem] font-semibold mt-4">
									Items{" "}
									<span className="bg-orange-600 text-white text-[1.4rem] font-medium py-[2px] px-[6px] rounded-full">{total}</span>
								</p>
								<div className="grid gap-4">
									{row.variantsonOrder?.map((variantOnOrder, index) => (
										<ItemInfo key={index} variantOnOrder={variantOnOrder} />
									))}
								</div>
								<div className="grid gap-4 p-8 mt-10 border rounded-lg border-lightGray">
									<InfoRow
										title="Delivery status"
										info={row?.status}
										infoClassName={`font-medium rounded-lg min-w-fit ${orderStatusGenerator(row.status.toUpperCase())}`}
									/>
									<InfoRow title="Delivery provider" info={row?.shippingCompany} />
									<InfoRow title="Subtotal" info={renderCurrency(row?.price)} />
									<InfoRow title="Delivery price" info={renderCurrency(row?.shippingFees)} />
									<InfoRow title="Total price" info={renderCurrency(row?.price + row?.shippingFees)} />
									<InfoRow title="Delivered on" info={!!row.deliveredAt ? format(new Date(row.deliveredAt), "dd MMM yyyy") : "-"} />
								</div>
							</div>
						</SheetContent>
					</Sheet>
				);
			},
			width: "105px",
		},
	];

	const { data, isLoading } = useQuery({
		queryKey: ["orders", page, search, status, startDate, endDate],
		queryFn: () =>
			getOrders({
				page,
				search,
				status,
				startDate: startDate ? format(startDate, "dd/MM/yyyy") : null,
				endDate: endDate ? format(endDate, "dd/MM/yyyy") : null,
			}),
	});

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No orders</p>
				</EmptyState>
			}
		/>
	);
};

const Transactions = ({ search, transactionStatus, startDate, endDate, paymentProcessor }) => {
	const [searchParams] = useSearchParams();
	const page = searchParams.get("page") ?? 1;

	const columns = [
		{
			name: "Transaction ID",
			selector: (row) => row.reference,
			grow: 2,
		},
		{
			name: "Order ID",
			cell: (row) => <div className="px-3 py-2 font-semibold bg-gray-100 rounded-lg">Order #{row?.order?.id}</div>,
			maxWidth: "170px",
			minWidth: "120px",
		},
		{ name: "Business", selector: (row) => row?.business?.name, style: { textTransform: "capitalize" } },
		{
			name: "Payment Platform",
			selector: (row) => returnPaymentProcessorIcon(row.paymentProcessor),
			maxWidth: "170px",
			minWidth: "120px",
		},
		{ name: "Transaction Date", selector: (row) => format(new Date(row.createdAt), "dd MMM yyyy"), maxWidth: "150px", minWidth: "140px" },
		{ name: "Amount", selector: (row) => renderCurrency(row.price), maxWidth: "140px", minWidth: "130px" },
		{
			name: "Status",
			cell: (row) => (
				<div
					className={`px-6 py-3 font-medium capitalize rounded-full min-w-fit ${transactionStatusGenerator(
						row?.isPaidOut ? "paid out" : row.status
					)}`}
				>
					{row.status}
				</div>
			),
			maxWidth: "150px",
			minWidth: "120px",
		},
	];

	const { data, isLoading } = useQuery({
		queryKey: ["orders", page, search, transactionStatus, startDate, endDate, paymentProcessor],
		queryFn: () =>
			getTransactions({
				page,
				search,
				status: transactionStatus,
				paymentProcessor,
				startDate: startDate ? format(startDate, "dd/MM/yyyy") : null,
				endDate: endDate ? format(endDate, "dd/MM/yyyy") : null,
			}),
	});

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No transactions</p>
				</EmptyState>
			}
		/>
	);
};

const RenderActions = ({
	search,
	setSearch,
	status,
	setStatus,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	paymentProcessor,
	setPaymentProcessor,
	transactionStatus,
	setTransactionStatus,
}) => {
	const [searchParams] = useSearchParams();
	const dateFormat = "dd/MM/yyyy";

	const currentTab = searchParams.get("tab") || "orders";

	return (
		<div className="flex items-center gap-4">
			<CustomInput
				icon={<SearchIcon className="w-[17px] h-[17px] text-gray-400" />}
				value={search}
				onChange={(event) => setSearch(event.target.value)}
				placeholder="Search..."
				innerClassName="!px-6 !py-3"
				className="!text-gray-500 placeholder:text-gray-400"
			/>
			<Popover>
				<PopoverTrigger asChild>
					<Button id="date" variant="outline" className="text-left font-normal text-gray-500 text-[1.4rem] rounded-lg px-4 py-3 uppercase">
						{startDate ? format(startDate, dateFormat) : dateFormat} - {endDate ? format(endDate, dateFormat) : dateFormat}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={startDate}
						selected={{ from: startDate, to: endDate }}
						onSelect={({ from, to }) => {
							setStartDate(from);
							setEndDate(to);
						}}
						numberOfMonths={2}
					/>
				</PopoverContent>
			</Popover>
			<Popover>
				<PopoverTrigger className="flex items-center gap-4 px-4 text-[1.4rem] py-3 text-gray-500 bg-gray-100 border-gray-200 rounded-lg">
					<FilterIcon />
					Filter
				</PopoverTrigger>
				<PopoverContent className="w-[250px]" align="left">
					<Accordion className="grid gap-4" type="multiple" collapsible>
						<button
							className="ml-auto text-red-500 hover:underline text-[1.4rem]"
							onClick={() => {
								setPaymentProcessor("");
								setStatus([]);
								setTransactionStatus("");
							}}
						>
							Reset
						</button>
						{currentTab === "orders" && (
							<AccordionItem value="item-1">
								<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">Status</AccordionTrigger>
								<AccordionContent className="grid gap-5 px-8 py-6">
									{statuses?.map((el, index) => (
										<CustomCheckbox
											key={index}
											label={el}
											value={el}
											name="status"
											type="checkbox"
											checked={status.includes(el)}
											onChange={(event) =>
												setStatus((prevState) => {
													if (event.target.checked) {
														const set = new Set([...prevState, event.target.value]);
														return Array.from(set);
													} else {
														const set = new Set([...prevState]);
														set.delete(event.target.value);
														return Array.from(set);
													}
												})
											}
										/>
									))}
								</AccordionContent>
							</AccordionItem>
						)}
						{currentTab === "transactions" && (
							<>
								<AccordionItem value="item-1">
									<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">
										Status
									</AccordionTrigger>
									<AccordionContent className="grid gap-5 px-8 py-6">
										<CustomCheckbox
											label="Pending"
											value="pending"
											name="transactionStatus"
											type="checkbox"
											checked={transactionStatus === "pending"}
											onChange={(event) => setTransactionStatus(event.target.value)}
										/>
										<CustomCheckbox
											label="Success"
											value="success"
											name="transactionStatus"
											type="checkbox"
											checked={transactionStatus === "success"}
											onChange={(event) => setTransactionStatus(event.target.value)}
										/>
										<CustomCheckbox
											label="Failed"
											value="failed"
											name="transactionStatus"
											type="checkbox"
											checked={transactionStatus === "failed"}
											onChange={(event) => setStatus(event.target.value)}
										/>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-2">
									<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">
										Payment Processor
									</AccordionTrigger>
									<AccordionContent className="grid gap-5 px-8 py-6">
										<CustomCheckbox
											label="Paystack"
											value="paystack"
											name="paymentProcessor"
											type="checkbox"
											checked={paymentProcessor === "paystack"}
											onChange={(event) => setPaymentProcessor(event.target.value)}
										/>
										<CustomCheckbox
											label="Stripe"
											value="stripe"
											name="paymentProcessor"
											type="checkbox"
											checked={paymentProcessor === "stripe"}
											onChange={(event) => setPaymentProcessor(event.target.value)}
										/>
									</AccordionContent>
								</AccordionItem>
							</>
						)}
					</Accordion>
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default Orders;
