import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ProtectedRoutesProvider from "./context/ProtectedRoutes";
import { AuthLayout, Login, OTP, Register, ResetPassword } from "./pages/auth";
import { DashboardLayout } from "./layouts";
import { Admins, Clients, Configuration, Dashboard, Orders, Security, Subscriptions, Tickets } from "./pages";

function App() {
	const router = createBrowserRouter([
		{
			path: "/auth",
			element: <AuthLayout />,
			children: [
				{
					path: "",
					element: <Login />,
				},
				{
					path: "otp",
					element: <OTP />,
				},
				{
					path: "register",
					element: <Register />,
				},
				{
					path: "reset-password",
					element: <ResetPassword />,
				},
			],
		},
		{
			path: "/",
			element: <DashboardLayout />,
			children: [
				{
					path: "",
					element: <Dashboard />,
					handle: { header: "Dashboard" },
				},
				{
					path: "clients",
					element: <Clients />,
					handle: { header: "SMB Clients" },
				},
				{
					path: "admins",
					element: <Admins />,
					handle: { header: "Administrators" },
				},
				{
					path: "orders",
					element: <Orders />,
					handle: { header: "Orders" },
				},
				{
					path: "tickets",
					element: <Tickets />,
					handle: { header: "Support Tickets" },
				},
				{
					path: "subscriptions",
					element: <Subscriptions />,
					handle: { header: "Subscriptions" },
				},
				{
					path: "configuration",
					element: <Configuration />,
					handle: { header: "Configuration" },
				},
				{
					path: "security",
					handle: { header: "Security" },
					element: <Security />,
				},
			],
		},
	]);
	return (
		<ProtectedRoutesProvider>
			<RouterProvider router={router} />
		</ProtectedRoutesProvider>
	);
}

export default App;
