import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, isBefore } from "date-fns";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FilterIcon, LogoNoText, NoDataIcon, OptionsIcon, RightArrowHeadIcon, SearchIcon } from "src/assets/svg";
import { CustomCheckbox, CustomDataTable, CustomInput, EmptyState, Files, InfoColumn, Modal, StatCard } from "src/components";
import { BiggerFiles } from "src/components/Files";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "src/components/ui/accordion";
import { Button } from "src/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "src/components/ui/sheet";
import { useToast } from "src/components/ui/use-toast";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from "src/components/ui/dropdown-menu";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { getBusinessLogs, getSMBAnalytics, getSMBs, getSMBsByName, resetKYBAttempts, suspensionToggle } from "src/services/api";
import { getEndpointName, removeFirstSubdirectory, renderConfirmDialogue, renderCurrency, renderNumber } from "src/utils/functions";
import { Calendar } from "src/components/ui/calendar";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "src/components/ui/command";
import { CheckIcon } from "lucide-react";
import { cn } from "src/utils";
import { PiCaretDown } from "react-icons/pi";
import { DeleteSMB } from "src/modals";

const generateInitiatorClasses = (initiator) =>
	initiator === "Customer"
		? "text-green-500 bg-green-100 uppercase"
		: initiator === "OWNER"
		? "text-[#2563EB] bg-[#EFF6FF]"
		: "text-[#4B5563] bg-[#F3F4F6]";
const generateTypeClasses = (type) => (type === "VERIFIED" ? "text-[#2563EB] bg-[#EFF6FF]" : "text-[#4B5563] bg-[#F3F4F6]");
const generateStatusClasses = (status) =>
	status === "ONLINE" ? "text-green-600 border border-green-300 bg-green-100" : "text-red-600 border border-red-300 bg-red-100";
const generateSubscriptionClasses = (subscription) =>
	!subscription
		? ""
		: subscription === "Basic"
		? "text-[#4B5563] bg-[#F3F4F6]"
		: subscription === "Premium"
		? "text-[#2563EB] border border-[#BFDBFE] bg-[#EFF6FF]"
		: "text-[#16A34A] border border-[#BBF7D0] bg-[#F0FDF4]";

const tabs = { activity: 0, "pending-approvals": 1, database: 2, deleted: 3 };

const Clients = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");
	const [kybVerified, setKybVerified] = useState();
	const [isSuspended, setIsSuspended] = useState();
	const [type, setType] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [businessId, setBusinessId] = useState("");

	const { data: rawAnalytics } = useQuery({
		queryKey: ["smb-analytics"],
		queryFn: getSMBAnalytics,
		suspense: true,
	});
	const analytics = rawAnalytics?.data;

	return (
		<div>
			<div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-6 mb-10">
				<StatCard title="TOTAL SMB CLIENTS" amount={renderNumber(analytics?.totalCount)} />
				<StatCard title="ACTIVELY SUBSCRIBED CLIENTS" amount={renderNumber(analytics?.subscribedCount)} />
				<StatCard title="CLIENTS PENDING VERIFICATION" amount={renderNumber(analytics?.pendingVerificationCount)} />
			</div>
			<Tabs
				defaultIndex={searchParams.has("tab") ? tabs[searchParams.get("tab")] : 0}
				onSelect={(_, __, event) => setSearchParams({ tab: event.target.dataset.id })}
			>
				<div className="flex flex-wrap items-center justify-between gap-10 mb-8">
					<TabList style={{ marginBottom: 0 }}>
						<Tab data-id="activity">Activity Monitor</Tab>
						<Tab data-id="pending-approvals">Pending Approvals</Tab>
						<Tab data-id="database">All SMBs</Tab>
						<Tab data-id="deleted">Deleted SMBs</Tab>
					</TabList>
					<RenderActions
						{...{
							search,
							setSearch,
							status,
							setStatus,
							kybVerified,
							setKybVerified,
							isSuspended,
							setIsSuspended,
							type,
							setType,
							startDate,
							setStartDate,
							endDate,
							setEndDate,
							businessId,
							setBusinessId,
						}}
					/>
				</div>

				<TabPanel>
					<ActivityMonitor {...{ startDate, endDate, businessId }} />
				</TabPanel>
				<TabPanel>
					<PendingApprovals {...{ search }} />
				</TabPanel>
				<TabPanel>
					<Database {...{ search, status, kybVerified, isSuspended, type }} />
				</TabPanel>
				<TabPanel>
					<Deleted {...{ search }} />
				</TabPanel>
			</Tabs>
		</div>
	);
};

const ActivityMonitor = ({ startDate, endDate, businessId }) => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;

	const columns = [
		{
			name: "Business name",
			cell: (row) => (
				<div className="flex items-center gap-3 overflow-hidden whitespace-nowrap">
					{row?.business?.logo ? (
						<img className="w-[32px] aspect-square rounded-xl" src={row?.business?.logo} alt="" />
					) : (
						<div className="min-w-[32px] aspect-square rounded-xl bg-[#F3F4F6]" />
					)}
					{row?.business?.name}
				</div>
			),
			grow: 1.4,
		},
		{
			name: "Event",
			selector: (row) => getEndpointName(removeFirstSubdirectory(row?.action?.urlRoute), row?.action?.method?.toLowerCase()),
			grow: 2,
		},
		{
			name: "Initiator",
			cell: (row) => (
				<div className={`px-6 py-3 font-medium rounded-lg ${generateInitiatorClasses(row?.staff?.role ?? "Customer")}`}>
					{row?.staff?.role ?? "Customer"}
				</div>
			),
		},
		{ name: "Date/Time", selector: (row) => format(new Date(row.createdAt), "dd MMM yyyy") },
		// {
		// 	name: "",
		// 	cell: () => (
		// 		<button className="flex items-center gap-2 text-[#050505] font-medium text-[1.6rem]">
		// 			View <RightArrowHeadIcon />
		// 		</button>
		// 	),
		// 	width: "105px",
		// },
	];

	const { data, isLoading } = useQuery({
		queryKey: ["businessLogs", page, startDate, endDate, businessId],
		queryFn: () =>
			getBusinessLogs({
				page,
				startDate: startDate ? startDate.toISOString() : null,
				endDate: endDate ? endDate.toISOString() : null,
				businessId,
			}),
	});

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No activities</p>
				</EmptyState>
			}
		/>
	);
};

const PendingApprovals = ({ search: text }) => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;

	const { data, isLoading } = useQuery({
		queryKey: ["pending-smbs", page, text],
		queryFn: () => getSMBs({ page, kybVerified: 0, text }),
	});

	const columns = [
		{
			name: "Business name",
			cell: (row) => (
				<div className="flex items-center gap-3 overflow-hidden capitalize whitespace-nowrap">
					{row?.logo ? (
						<img className="w-[32px] aspect-square rounded-xl" src={row?.logo} alt="" />
					) : (
						<div className="min-w-[32px] aspect-square rounded-xl bg-[#F3F4F6]" />
					)}
					{row.name}
				</div>
			),
			grow: 1.2,
		},
		{
			name: "Type",
			cell: (row) => (
				<div className={`px-6 py-3 font-medium rounded-lg capitalize ${generateTypeClasses(row.type)}`}>
					{row.type === "VERIFIED" ? "REGISTERED" : "UNREGISTERED"}
				</div>
			),
			width: "170px",
		},
		{
			name: "Documents",
			cell: (row) => (
				<div className="flex items-center gap-4 overflow-hidden">
					{row?.kyb?.documentUrl && (
						<Files
							name={row?.type === "VERIFIED" ? "Certificate of Incorporation" : "Indemnity Form"}
							link={row?.kyb?.documentUrl}
							fileType="pdf"
						/>
					)}
					{row?.kyb?.passport && <Files name="Passport" link={row?.kyb?.passport} fileType="image" />}
					{row?.kyb?.validId && <Files name="Valid ID" link={row?.kyb?.validId} fileType="image" />}
				</div>
			),
			grow: 2,
			minWidth: "350px",
		},
		{
			name: "",
			cell: (row) => (
				<Sheet>
					<SheetTrigger asChild>
						<Button className="flex items-center hover:bg-transparent bg-transparent border-0 gap-2 text-[#050505] font-medium text-[1.6rem]">
							View <RightArrowHeadIcon />
						</Button>
					</SheetTrigger>
					<SheetContent>
						<SheetHeader className="">
							<SheetTitle className="">Pending approvals</SheetTitle>
						</SheetHeader>
						<div className="grid gap-6">
							<div className="flex items-center gap-5">
								{row?.logo ? (
									<img className="w-[60px] h-[60px] rounded-lg" src={row?.logo} alt="" />
								) : (
									<div className="w-[60px] h-[60px] rounded-lg bg-black" />
								)}
								<div>
									<p className="text-[#1F2937] text-[1.6rem] font-semibold capitalize">{row?.name}</p>
									<p className="text-[#6D7280] text-[1.5rem]">{row?.info?.contactEmail}</p>
								</div>
							</div>
							<div className="grid grid-cols-[repeat(auto-fit,minmax(120px,1fr))] gap-4 border-b border-lightGray pb-12">
								<InfoColumn title="Type" info={row?.type} />
								<InfoColumn title="Phone" info={`${row?.info?.contactPhonePrefix ?? "-"}${row?.info?.contactPhone ?? "-"}`} />
								<InfoColumn title="Country" info={row?.info?.country ?? "-"} />
							</div>
							<div className="grid gap-4 mt-4">
								<p className="text-[#9CA3AF] text-[1.6rem] font-medium">Uploads</p>
								{row?.kyb?.documentUrl && (
									<BiggerFiles name="Certificate of Incorporation" fileType="pdf" link={row?.kyb?.documentUrl} />
								)}
								{row?.kyb?.passport && <BiggerFiles name="Passport" fileType="image" link={row?.kyb?.passport} />}
								{row?.kyb?.validId && <BiggerFiles name="Valid ID" fileType="pdf" link={row?.kyb?.validId} />}
							</div>
						</div>
					</SheetContent>
				</Sheet>
			),
			width: "105px",
		},
	];

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No pending approvals</p>
				</EmptyState>
			}
		/>
	);
};

const Deleted = ({ search: text }) => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;

	const { data, isLoading } = useQuery({
		queryKey: ["deleted-smbs", page, text],
		queryFn: () => getSMBs({ page, text, status: "FLAGGEDOFFLINE" }),
	});

	const columns = [
		{
			name: "Business name",
			cell: (row) => (
				<div className="flex items-center gap-3 overflow-hidden capitalize whitespace-nowrap">
					{row?.logo ? (
						<img className="w-[32px] aspect-square rounded-xl" src={row?.logo} alt="" />
					) : (
						<div className="min-w-[32px] aspect-square rounded-xl bg-[#F3F4F6]" />
					)}
					{row.name}
				</div>
			),
		},
		{ name: "Email", selector: (row) => row?.info?.contactEmail ?? "-" },
		{
			name: "Type",
			cell: (row) => (
				<div className={`px-6 py-3 font-medium rounded-lg capitalize ${generateTypeClasses(row.type)}`}>
					{row.type === "VERIFIED" ? "REGISTERED" : "UNREGISTERED"}
				</div>
			),
		},
		{
			name: "",
			cell: (row) => (
				<Sheet>
					<SheetTrigger asChild>
						<Button className="flex items-center hover:bg-transparent bg-transparent border-0 gap-2 text-[#050505] font-medium text-[1.6rem]">
							View <RightArrowHeadIcon />
						</Button>
					</SheetTrigger>
					<SheetContent>
						<SheetHeader className="">
							<SheetTitle className="">Deleted Business</SheetTitle>
						</SheetHeader>
						<div className="grid gap-6">
							<div className="flex items-center gap-5">
								{row?.logo ? (
									<img className="w-[60px] h-[60px] rounded-lg" src={row?.logo} alt="" />
								) : (
									<div className="w-[60px] h-[60px] rounded-lg bg-black" />
								)}
								<div>
									<p className="text-[#1F2937] text-[1.6rem] font-semibold capitalize">{row?.name}</p>
									<p className="text-[#6D7280] text-[1.5rem]">{row?.info?.contactEmail}</p>
								</div>
							</div>
							<div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4 border-b border-lightGray pb-12">
								<InfoColumn title="Type" info={row?.type} />
								<InfoColumn title="Phone" info={`${row?.info?.contactPhonePrefix ?? "-"}${row?.info?.contactPhone ?? "-"}`} />
								<InfoColumn title="Country" info={row?.info?.country ?? "-"} />
							</div>
							<div className="grid gap-4 mt-4">
								<p className="text-[#9CA3AF] text-[1.6rem] font-medium">Reason for Deletion</p>
								<p className="text-darkBlue font-semibold text-[1.6rem]">{row?.deleteReason?.reason}</p>
							</div>
						</div>
					</SheetContent>
				</Sheet>
			),
			width: "105px",
		},
	];

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No available clients</p>
				</EmptyState>
			}
		/>
	);
};

const Database = ({ search: text, status, kybVerified, isSuspended, type }) => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;

	const { data, isLoading } = useQuery({
		queryKey: ["smbs", page, text, status, type, kybVerified, isSuspended],
		queryFn: () => getSMBs({ page, text, status, type, kybVerified, isSuspended }),
	});

	const columns = [
		{
			name: "Business name",
			cell: (row) => (
				<div className="flex items-center gap-3 overflow-hidden capitalize whitespace-nowrap">
					{row?.logo ? (
						<img className="w-[32px] aspect-square rounded-xl" src={row?.logo} alt="" />
					) : (
						<div className="min-w-[32px] aspect-square rounded-xl bg-[#F3F4F6]" />
					)}
					{row.name}
				</div>
			),
			grow: 1.4,
		},
		{ name: "Email", selector: (row) => row?.info?.contactEmail ?? "-" },
		{
			name: "Status",
			cell: (row) => <div className={`px-6 py-3 font-medium rounded-lg capitalize ${generateStatusClasses(row.status)}`}>{row.status}</div>,
			width: "170px",
		},
		{
			name: "Type",
			cell: (row) => (
				<div className={`px-6 py-3 font-medium rounded-lg capitalize ${generateTypeClasses(row.type)}`}>
					{row.type === "VERIFIED" ? "REGISTERED" : "UNREGISTERED"}
				</div>
			),
			width: "170px",
		},
		{
			name: "Subscription",
			cell: (row) => (
				<div className={`px-6 py-3 font-medium rounded-lg ${generateSubscriptionClasses(row?.lastSubscription?.plan?.name)}`}>
					{row?.lastSubscription?.plan?.name ?? "-"}
				</div>
			),
			width: "200px",
		},
		{ name: "Suspended?", selector: (row) => (row?.isSuspended ? "True" : "False"), width: "120px" },
		{
			name: "",
			cell: (row) => <ClientActions row={row} />,
			width: "50px",
		},
		{
			name: "",
			cell: (row) => (
				<Sheet>
					<SheetTrigger asChild>
						<Button className="flex items-center hover:bg-transparent bg-transparent border-0 gap-2 text-[#050505] font-medium text-[1.6rem]">
							View <RightArrowHeadIcon />
						</Button>
					</SheetTrigger>
					<SheetContent>
						<SheetHeader className="">
							<SheetTitle className="">SMB Details</SheetTitle>
						</SheetHeader>
						<div className="grid gap-6">
							<div className="flex items-center gap-5">
								{row?.logo ? (
									<img className="w-[60px] h-[60px] rounded-lg" src={row?.logo} alt="" />
								) : (
									<div className="w-[60px] h-[60px] rounded-lg bg-black" />
								)}
								<div>
									<p className="text-[#1F2937] text-[1.6rem] font-semibold capitalize">{row?.name}</p>
									<p className="text-[#6D7280] text-[1.5rem]">{row?.info?.contactEmail}</p>
								</div>
							</div>
							<div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4 border-b border-lightGray pb-12">
								<InfoColumn title="Type" info={row?.type} />
								<InfoColumn title="Phone" info={`${row?.info?.contactPhonePrefix ?? "-"}${row?.info?.contactPhone ?? "-"}`} />
								<InfoColumn title="Country" info={row?.info?.country ?? "-"} />
							</div>
							{!!row?.lastSubscription && (
								<div className="grid gap-4 mt-4">
									<p className="text-[#9CA3AF] text-[1.6rem] font-medium">Subscription</p>
									<SubscriptionCard {...row?.lastSubscription} />
								</div>
							)}
						</div>
					</SheetContent>
				</Sheet>
			),
			width: "105px",
		},
	];

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No available clients</p>
				</EmptyState>
			}
		/>
	);
};

const SubscriptionCard = ({ subscriptionEndDate, plan }) => {
	const today = new Date();

	return (
		<div className="grid gap-10 border border-gray-200 rounded-xl p-7">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-4">
					<LogoNoText className="text-green-500" />
					<p className="text-darkBlue text-[1.6rem] font-medium">{plan?.name}</p>
				</div>
				<p className="uppercase text-[#05050580] text-[1.2rem] font-semibold px-4 py-1 rounded-md bg-gray-200">
					{isBefore(today, subscriptionEndDate) ? "Active" : "Expired"}
				</p>
			</div>
			<p className="text-[1.6rem] text-gray-500">{plan?.description}</p>
			<p className="text-darkBlue text-[2.4rem] font-semibold">
				{renderCurrency(plan?.price, plan?.currency)} <span className="text-gray-400 text-[1.6rem] font-medium">{plan?.tenure}</span>
			</p>
		</div>
	);
};

const RenderActions = ({
	search,
	setSearch,
	status,
	setStatus,
	kybVerified,
	setKybVerified,
	isSuspended,
	setIsSuspended,
	type,
	setType,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	businessId,
	setBusinessId,
}) => {
	const dateFormat = "dd/MM/yyyy";

	const [searchParams] = useSearchParams();
	const currentTab = searchParams.get("tab") || "activity";

	const [name, setName] = useState("");

	const { data } = useQuery({
		queryKey: ["smb-name", name],
		queryFn: () => getSMBsByName(name),
		enabled: !!name,
	});

	const smbs = data?.data?.map((business) => ({ value: business?.id?.toString(), label: business?.name }));

	return (
		<div className="flex flex-wrap items-center gap-4">
			{currentTab === "activity" && (
				<>
					<Popover>
						<PopoverTrigger asChild>
							<Button
								variant="outline"
								role="combobox"
								className={cn(
									"w-[200px] px-4 py-3 justify-between font-normal min-h-[3.8rem] text-gray-500 text-[1.4rem] rounded-lg",
									!businessId && "text-muted-foreground"
								)}
							>
								<span>{businessId ? smbs?.find((business) => business.value === businessId)?.label : "Select business"}</span>
								<PiCaretDown className="w-[14px] h-[14px] text-gray-600 ml-2 shrink-0" />
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-[200px] p-0">
							<Command>
								<CommandInput
									placeholder="Search business..."
									className="h-9"
									value={name}
									onInput={(event) => setName(event.target.value)}
								/>
								<CommandList>
									<CommandEmpty>No business found.</CommandEmpty>
									<CommandGroup>
										{smbs?.map((business) => (
											<CommandItem value={business.label} key={business.value} onSelect={() => setBusinessId(business.value)}>
												{business.label}
												<CheckIcon
													className={cn("ml-auto h-4 w-4", business.value === businessId ? "opacity-100" : "opacity-0")}
												/>
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</Command>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger asChild>
							<Button
								id="date"
								variant="outline"
								className="text-left font-normal text-gray-500 text-[1.5rem] rounded-lg px-4 py-3 uppercase"
							>
								{startDate ? format(startDate, dateFormat) : dateFormat} - {endDate ? format(endDate, dateFormat) : dateFormat}
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								initialFocus
								mode="range"
								defaultMonth={startDate}
								selected={{ from: startDate, to: endDate }}
								onSelect={({ from, to }) => {
									setStartDate(from);
									setEndDate(to);
								}}
								numberOfMonths={2}
							/>
						</PopoverContent>
					</Popover>
				</>
			)}
			{currentTab !== "activity" && (
				<CustomInput
					icon={<SearchIcon className="w-[17px] h-[17px] text-gray-400" />}
					value={search}
					onChange={(event) => setSearch(event.target.value)}
					placeholder="Search..."
					innerClassName="!px-6 !py-3"
					className="!text-gray-500 placeholder:text-gray-400"
				/>
			)}
			{currentTab === "database" && (
				<Popover>
					<PopoverTrigger className="flex items-center gap-4 px-4 text-[1.4rem] py-3 text-gray-500 bg-gray-100 border-gray-200 rounded-lg">
						<FilterIcon />
						Filter
					</PopoverTrigger>
					<PopoverContent className="w-[250px]" align="left">
						<Accordion className="grid gap-4" type="multiple" collapsible>
							<button
								className="ml-auto text-red-500 hover:underline text-[1.4rem]"
								onClick={() => {
									setStatus("");
									setKybVerified();
									setIsSuspended();
									setType("");
								}}
							>
								Reset
							</button>
							<AccordionItem value="item-1">
								<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">Status</AccordionTrigger>
								<AccordionContent className="grid gap-5 px-8 py-6">
									<CustomCheckbox
										label="Online"
										value="ONLINE"
										name="status"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={status === "ONLINE"}
										onChange={(event) => setStatus(event.target.value)}
									/>
									<CustomCheckbox
										label="Offline"
										value="OFFLINE"
										name="status"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={status === "OFFLINE"}
										onChange={(event) => setStatus(event.target.value)}
									/>
								</AccordionContent>
							</AccordionItem>
							<AccordionItem value="item-2">
								<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">
									KYB Verified
								</AccordionTrigger>
								<AccordionContent className="grid gap-5 px-8 py-6">
									<CustomCheckbox
										label="True"
										value={1}
										name="kybVerified"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={kybVerified === 1}
										onChange={(event) => setKybVerified(Number(event.target.value))}
									/>
									<CustomCheckbox
										label="False"
										value={0}
										name="kybVerified"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={kybVerified === 0}
										onChange={(event) => setKybVerified(Number(event.target.value))}
									/>
								</AccordionContent>
							</AccordionItem>
							<AccordionItem value="item-3">
								<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">Type</AccordionTrigger>
								<AccordionContent className="grid gap-5 px-8 py-6">
									<CustomCheckbox
										label="Unregistered"
										value="UNVERIFIED"
										name="type"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={type === "UNVERIFIED"}
										onChange={(event) => setType(event.target.value)}
									/>
									<CustomCheckbox
										label="Registered"
										value="VERIFIED"
										name="type"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={type === "VERIFIED"}
										onChange={(event) => setType(event.target.value)}
									/>
								</AccordionContent>
							</AccordionItem>
							<AccordionItem value="item-4">
								<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">
									Suspension
								</AccordionTrigger>
								<AccordionContent className="grid gap-5 px-8 py-6">
									<CustomCheckbox
										label="True"
										value={1}
										name="isSuspended"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={isSuspended === 1}
										onChange={(event) => setIsSuspended(Number(event.target.value))}
									/>
									<CustomCheckbox
										label="False"
										value={0}
										name="isSuspended"
										type="radio"
										labelClassName="!text-[1.4rem]"
										checked={isSuspended === 0}
										onChange={(event) => setIsSuspended(Number(event.target.value))}
									/>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</PopoverContent>
				</Popover>
			)}
		</div>
	);
};

const ClientActions = ({ row }) => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const { mutate: resetKYBMutate } = useMutation(resetKYBAttempts, {
		onSuccess: ({ message }) => {
			queryClient.refetchQueries({ queryKey: ["smbs"] });
			toast({ title: "Success", description: message });
		},
		onError: (error) => error?.cause === 402 && setIsPasswordModalOpen(true),
	});
	const { mutate: suspensionStatusMutate } = useMutation(suspensionToggle, {
		onSuccess: ({ message }) => {
			queryClient.refetchQueries({ queryKey: ["smbs"] });
			toast({ title: "Success", description: message });
		},
		onError: (error) => error?.cause === 402 && setIsPasswordModalOpen(true),
	});

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger>
					<OptionsIcon className="w-[18px] h-[18px] text-gray-900" />
				</DropdownMenuTrigger>
				<DropdownMenuContent className="max-w-[180px]">
					<DropdownMenuLabel>Actions</DropdownMenuLabel>
					<DropdownMenuSeparator />
					{row?.numberOfKYBAttempts >= 3 && (
						<DropdownMenuItem
							disabled={row?.ktyVerified}
							onClick={() => renderConfirmDialogue().then(() => resetKYBMutate({ id: row?.id }))}
						>
							Reset KYB Attempts
						</DropdownMenuItem>
					)}
					{row?.isSuspended ? (
						<DropdownMenuItem
							disabled={!row?.ktyVerified}
							onClick={() => renderConfirmDialogue().then((res) => res && suspensionStatusMutate({ id: row?.id, isSuspended: false }))}
						>
							Unsuspend SMB
						</DropdownMenuItem>
					) : (
						<DropdownMenuItem
							disabled={!row?.ktyVerified}
							onClick={() => renderConfirmDialogue().then((res) => res && suspensionStatusMutate({ id: row?.id, isSuspended: true }))}
						>
							Suspend SMB
						</DropdownMenuItem>
					)}
					<DropdownMenuItem className="text-red-500" onClick={() => setIsModalOpen(true)}>
						Delete SMB
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>

			<Modal
				isOpen={isModalOpen}
				setIsOpen={setIsModalOpen}
				title={`Delete ${row?.name}`}
				subtitle="Please provide the reason you want to delete this SMB"
				size="sm"
			>
				<DeleteSMB row={row} />
			</Modal>
		</>
	);
};

export default Clients;
