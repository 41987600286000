import { createContext, useContext, useEffect, useState } from "react";
import { Modal } from "src/components";
import { PasswordToken } from "src/modals";
import { getFromStorage, setToStorage } from "src/utils/functions";

const ProtectedRoutesContext = createContext({
	user: null,
	setUser: () => {},
	setToken: () => {},
	token: null,
	isPasswordModalOpen: false,
	setIsPasswordModalOpen: () => {},
});

export const useProtectedRoutesContext = () => useContext(ProtectedRoutesContext);

const ProtectedRoutesProvider = ({ children }) => {
	const [user, setUser] = useState(getFromStorage("user"));
	const [token, setToken] = useState(getFromStorage("token"));
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

	useEffect(() => {
		setToStorage("user", user);
	}, [user]);
	useEffect(() => {
		setToStorage("token", token);
	}, [token]);

	return (
		<ProtectedRoutesContext.Provider value={{ user, setUser, token, setToken, isPasswordModalOpen, setIsPasswordModalOpen }}>
			{children}
			<Modal isOpen={isPasswordModalOpen} setIsOpen={setIsPasswordModalOpen} title="Password Token" size="sm">
				<PasswordToken />
			</Modal>
		</ProtectedRoutesContext.Provider>
	);
};

export default ProtectedRoutesProvider;
