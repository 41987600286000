import React from "react";
import { Spinner } from "./Loader";

const StatCard = ({ title, amount, isLoading }) => {
	if (isLoading)
		return (
			<div className="p-[1.6rem] border border-lightGray rounded-lg">
				<Spinner />
			</div>
		);

	return (
		<div className="p-[1.6rem] border border-lightGray rounded-lg">
			<p className="text-[#05050580] text-[1.2rem] font-medium mb-3 uppercase">{title}</p>
			<p className="text-[#050505] text-[2.4rem] font-medium">{amount}</p>
		</div>
	);
};

export default StatCard;
