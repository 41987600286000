import { ErrorMessage } from "formik";
import React from "react";

export const CustomTextArea = ({ label, name, containerClassName, ...props }) => {
	return (
		<div className={`grid h-fit gap-3 ${containerClassName}`}>
			<label htmlFor="" className="block text-[1.4rem] font-medium text-gray mb-1">
				{label}
			</label>
			<textarea
				rows={5}
				name={name}
				className="border border-[#D2D5DA] rounded-xl py-[1.3rem] px-[1.6rem] text-[1.4rem] text-black placeholder:text-[#919BA7]"
				{...props}
			></textarea>
			{name && <ErrorMessage name={name} component="div" className="text-red-500 text-[1.2rem] mt-1" />}
		</div>
	);
};

export default CustomTextArea;
