import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { CustomInput } from "src/components";
import { useModalContext } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { useToast } from "src/components/ui/use-toast";
import { passwordToken } from "src/services/api";
import { setToStorage } from "src/utils/functions";
import * as Yup from "yup";

const PasswordToken = () => {
	const { toast } = useToast();
	const { setIsOpen } = useModalContext();

	const initialValues = {
		password: "",
	};
	const validationSchema = Yup.object().shape({
		password: Yup.string().required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(passwordToken, {
		onSuccess: ({ message, data }) => {
			toast({ title: "Success", description: message });
			setToStorage("password-token", data);
			setIsOpen(false);
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-10 pt-10">
					<CustomInput value={values.password} name="password" type="password" onChange={handleChange} placeholder="Enter password" />
					<Button disabled={isLoading} className="!py-4 !px-10 ml-auto text-[1.4rem] w-fit">
						Send
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default PasswordToken;
