import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { CustomInput } from "src/components";
import * as yup from "yup";
import { BannerComponent } from "./layout/AuthLayout";
import { PiCheck } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useMutation } from "@tanstack/react-query";
import { register } from "src/services/AuthAPI";
import { renderAlert } from "src/utils/functions";

const Register = () => {
	const [step, setStep] = useState(1);
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const navigate = useNavigate();
	const initialValues = { firstName: "", lastName: "", password: "", confirmPassword: "" };
	const phoneRegExp = /^(\+?\d{1,4}|\d{1,4})?\s?\d{10}$/;

	const { mutate, isLoading } = useMutation(register, {
		onSuccess: (res) => {
			renderAlert({
				title: "Success",
				msg: res?.message,
				type: "success",
			}).then(() => {
				navigate("/auth");
			});
		},
	});

	const validationSchemaFirst = yup.object({
		firstName: yup.string().required("First name is required"),
		lastName: yup.string().required("Last name is required"),
	});
	const validationSchemaSecond = yup.object({
		phone: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
	});
	const validationSchemaThird = yup.object({
		password: yup
			.string()
			.required("Password is required")
			.min(8, "Password must be at least 8 characters")
			.matches(/[A-Z]/, "Password must contain at least one uppercase letter")
			.matches(/\d/, "Password must contain at least one number"),
		confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
	});
	const handleSubmit = (values) => {
		if (step === 1) {
			validationSchemaFirst
				.validate(values)
				.then(() => setStep(2))
				.catch((errors) => {});
		} else if (step === 2) {
			validationSchemaSecond
				.validate(values)
				.then(() => setStep(3))
				.catch((errors) => {});
		} else {
			validationSchemaThird
				.validate(values)
				.then(({ ...result }) => mutate({ token, ...result }))
				.catch((err) => {});
		}
	};

	useEffect(() => {
		if (!token) {
			navigate("/auth");
		}
	}, [navigate, searchParams, token]);

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 shadow-[0_0px_24px_-4px_rgba(16,24,40,0.08)]  gap-[3rem] min-h-[50rem]">
			<BannerComponent />
			<div className="p-[5rem] flex flex-col justify-center  w-[45rem] md:w-auto">
				<Formik
					initialValues={initialValues}
					validationSchema={step === 1 ? validationSchemaFirst : step === 2 ? validationSchemaSecond : validationSchemaThird}
					onSubmit={handleSubmit}
				>
					{({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
						<form onSubmit={handleSubmit}>
							{step === 1 && (
								<>
									<div className="mb-[3rem]">
										<p className="text-[2.4rem] font-bold text-primary mb-3">You have been invited</p>
										<p className="text-[1.4rem] font-normal text-gray">Please enter your full name</p>
									</div>
									<div className="mb-[3rem]">
										<CustomInput
											name="firstName"
											value={values.firstName}
											onBlur={handleBlur}
											onChange={handleChange}
											placeholder="First name"
										/>
									</div>
									<div className="mb-[3rem]">
										<CustomInput
											value={values.lastName}
											name="lastName"
											onBlur={handleBlur}
											onChange={handleChange}
											placeholder="Last name"
										/>
									</div>
								</>
							)}
							{step === 2 && (
								<>
									<div className="mb-[3rem]">
										<p className="text-[2.4rem] font-bold text-primary mb-3">You have been invited</p>
										<p className="text-[1.4rem] font-normal text-gray">Please enter your full name</p>
									</div>
									<div>
										<div className="flex items-center border border-[#D2D5DA] focus-within:border-[#f97316] rounded-[6px]">
											<PhoneInput
												defaultCountry="ng"
												value={values.phone}
												name="phone"
												onChange={(phone) => setFieldValue("phone", phone)}
												inputClassName="w-full "
											/>
										</div>
										<ErrorMessage name="phone" component="div" className="text-red-500 text-[1.2rem] my-3" />
									</div>
								</>
							)}
							{step === 3 && (
								<>
									<div className="mb-[3rem]">
										<p className="text-[2.4rem] font-bold text-primary mb-3">You have been invited</p>
										<p className="text-[1.4rem] font-normal text-gray">Please enter a secure password</p>
									</div>
									<div className="mb-[3rem]">
										<CustomInput
											type="password"
											value={values.password}
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											placeholder="Password"
										/>
									</div>
									<div className="mb-[3rem]">
										<CustomInput
											type="password"
											value={values.confirmPassword}
											name="confirmPassword"
											onBlur={handleBlur}
											onChange={handleChange}
											placeholder="Confirm password"
										/>
									</div>

									<div className="text-[1.3rem] text-normal">
										<p
											className={`mb-2 flex items-center gap-[1rem] ${
												values.password.length >= 8 ? "text-[#16A34A]" : "text-[#4B5563]"
											}`}
										>
											{values.password.length >= 8 ? (
												<PiCheck className="text-[1.4rem]" />
											) : (
												<AiOutlineClose className="text-[1.4rem]" />
											)}
											At least 8 characters
										</p>
										<p
											className={`mb-2 flex items-center gap-[1rem] ${
												/[A-Z]/.test(values.password) ? "text-[#16A34A]" : "text-[#4B5563]"
											}`}
										>
											{/[A-Z]/.test(values.password) ? (
												<PiCheck className="text-[1.4rem]" />
											) : (
												<AiOutlineClose className="text-[1.4rem]" />
											)}
											At least 1 uppercase character
										</p>
										<p
											className={`mb-2 flex items-center gap-[1rem] ${
												/\d/.test(values.password) ? "text-[#16A34A]" : "text-[#4B5563]"
											}`}
										>
											{/\d/.test(values.password) ? (
												<PiCheck className="text-[1.4rem]" />
											) : (
												<AiOutlineClose className="text-[1.4rem]" />
											)}
											At least 1 number
										</p>
									</div>
								</>
							)}
							<div className="flex justify-between gap-[2rem] mt-[5rem]">
								{step > 1 && (
									<button
										type="button"
										onClick={() => setStep(step - 1)}
										disabled={isLoading}
										className=" w-full border border-primary text-primary rounded-[6px] h-[4.4rem] py-[.8rem] px-[2rem]"
									>
										Previous
									</button>
								)}
								<button
									type="submit"
									className="btn-loader bg-primary w-full text-white rounded-[6px] h-[4.4rem] py-[.8rem] px-[2rem]"
									disabled={isLoading}
								>
									{step === 3 ? "Submit" : "Continue"}
								</button>
							</div>
						</form>
					)}
				</Formik>
				{/*  <p className='mt-[3rem] text-[1.6rem] text-center text-lightDark'>Request help</p> */}
			</div>
		</div>
	);
};

export default Register;
