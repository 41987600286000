import React from "react";
import { renderCurrency } from "src/utils/functions";

const ItemInfo = ({ variantOnOrder }) => {
	const productName = variantOnOrder?.variant?.product?.name;
	const variantName = variantOnOrder?.variant?.name;
	const price = !!variantOnOrder?.variant?.price ? variantOnOrder?.variant?.price : variantOnOrder?.variant?.product?.price;
	const discount = !!variantOnOrder?.variant?.discount ? variantOnOrder?.variant?.discount : variantOnOrder?.variant?.product?.discount;

	const priceAfterDiscount = (1 - (discount ?? 0) / 100) * price;
	const priceQuantity = priceAfterDiscount * variantOnOrder?.quantity;

	return (
		<div className="flex items-center gap-4 @container/item-info">
			<div className="min-w-[5.6rem] h-[5.6rem] rounded-lg bg-darkBlue" />
			<div className="w-full">
				<div className="grid items-center @[450px]/item-info:grid-cols-[minmax(200px,2fr),1fr,1fr] @[450px]/item-info:gap-5">
					<p className="text-darkBlue flex-[1] text-[1.6rem] font-semibold">{productName}</p>
					<p className="text-darkBlue text-[1.4rem]">
						{renderCurrency(priceAfterDiscount)} x {variantOnOrder?.quantity}
					</p>
					<p className="text-darkBlue text-[1.6rem] font-semibold text-right">{renderCurrency(priceQuantity)}</p>
				</div>
				<p className="text-gray-500 text-[1.3rem]">{variantName}</p>
			</div>
		</div>
	);
};

export default ItemInfo;
