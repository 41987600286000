import { ErrorMessage } from "formik";
import React from "react";
import { MultiSelect } from "./ui/multi-select";

const CustomMultiSelect = ({ label, name, containerClassName, options, placeholder, ...props }) => {
	return (
		<div className={containerClassName}>
			{label && (
				<label htmlFor={name} className="block text-[1.4rem] font-medium text-gray mb-1">
					{label}
				</label>
			)}
			<MultiSelect options={options} placeholder={placeholder} {...props} />
			{name && <ErrorMessage name={name} component="div" className="text-red-500 text-[1.2rem] mt-1" />}
		</div>
	);
};

export default CustomMultiSelect;
