import axios from "axios";
import { renderToastMsg } from "src/utils/functions";

const requestUrl = () => {
	var url = window.origin;
	if (url?.includes("http://localhost")) {
		return process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`;
	} else {
		return process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL_LIVE}` : `${process.env.REACT_APP_PROD_BASEURL_LIVE}`;
	}
};

const authApi = axios.create({
	baseURL: requestUrl(),
	headers: {
		"Content-Type": "application/json",
	},
});

authApi.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response.status === 401) {
			renderToastMsg(error.response.data.error).then(() => {
				localStorage.clear();
			});
		} else if (error.response.status === 500) {
			renderToastMsg(error.response.data.error, "error");
		} else {
			if (error.response.data.error) {
				renderToastMsg(error.response.data.error, "error");
			} else {
				renderToastMsg(error.response.data.error, "error");
			}
		}
		throw new Error(error.response.data.error, {
			cause: error.response.status,
		});
	}
);

//* sign in
export const signin = (data) => {
	return authApi.post("/auth", data);
};

//* forgot password
export const forgotPassword = (data) => {
	return authApi.post("/auth/forgot-password", data);
};
//* reset password
export const resetPassword = (data) => {
	return authApi.post("/auth/reset-password", data);
};

//* confirm otp
export const confirmOTP = (data) => {
	return authApi.post("/auth/confirm-otp", data);
};

//*resent otp
export const resendOTP = (data) => {
	return authApi.post("/auth/confirm-otp", data);
};

//* sign up
export const register = (data) => {
	return authApi.patch("/auth", data);
};
