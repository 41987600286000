import React from "react";
import { DownloadIcon, EyeIcon, ImageIcon, PDFIcon } from "src/assets/svg";

const generateFileSVG = (fileType, className = "") => (fileType === "pdf" ? <PDFIcon {...{ className }} /> : <ImageIcon {...{ className }} />);

const Files = ({ name, link, fileType }) => {
	return (
		<a
			href={link}
			target="_blank"
			className="border border-lightGray flex items-center gap-2 px-3 py-2 min-w-fit h-fit rounded-md text-[1.4rem] font-medium text-darkBlue"
			rel="noreferrer"
		>
			{generateFileSVG(fileType)} {name}
		</a>
	);
};

export const BiggerFiles = ({ name, link, fileType }) => {
	return (
		<div className="flex items-center justify-between gap-2 px-4 py-3 border rounded-md border-lightGray min-w-fit h-fit">
			<div className="flex items-center gap-4 text-[1.6rem] font-medium text-darkBlue">
				{generateFileSVG(fileType, "w-[24px] h-[24px]")} {name}
			</div>
			<div className="flex items-center gap-4">
				<a href={link} target="_blank" className="p-3 bg-[#F3F4F6] rounded-md" rel="noreferrer">
					<EyeIcon />
				</a>
				<a href={link} download={true} className="p-3 bg-[#F3F4F6] rounded-md">
					<DownloadIcon />
				</a>
			</div>
		</div>
	);
};

export default Files;
