import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { CheckIcon } from "lucide-react";
import React, { useState } from "react";
import { PiCaretDown } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AddIcon, FilterIcon, NoDataIcon, OptionsIcon, SearchIcon } from "src/assets/svg";
import { CustomCheckbox, CustomDataTable, CustomInput, EmptyState, Modal } from "src/components";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "src/components/ui/accordion";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "src/components/ui/command";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from "src/components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { useToast } from "src/components/ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { ChangeStaffRole, CreateRole, InviteAdmin } from "src/modals";
import { changeAdminStatus, getAdminLogs, getAdminRoles, getAdmins, getAdminsByName, inviteAdmin } from "src/services/api";
import { cn } from "src/utils";
import { getEndpointName, renderConfirmDialogue } from "src/utils/functions";

const tabs = { activity: 0, database: 1 };
const generateStatusClasses = (status) =>
	status === "PENDING"
		? "text-orange-600 bg-[#FFF7ED]"
		: status === "ACTIVE"
		? "text-[#16A34A] border border-[#BBF7D0] bg-[#F0FDF4]"
		: "text-red-500 border border-red-200 bg-red-200";
const generateInitiatorClasses = (initiator) => (initiator === "Owner" ? "text-[#2563EB] bg-[#EFF6FF]" : "text-[#4B5563] bg-[#F3F4F6]");

const Admins = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");
	const [role, setRole] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [adminId, setAdminId] = useState("");

	return (
		<Tabs
			defaultIndex={searchParams.has("tab") ? tabs[searchParams.get("tab")] : 0}
			onSelect={(_, __, event) => setSearchParams({ tab: event.target.dataset.id })}
		>
			<div className="flex flex-wrap items-center justify-between gap-10 mb-8">
				<TabList style={{ marginBottom: 0 }}>
					<Tab data-id="activity">Activity Monitor</Tab>
					<Tab data-id="database">All Admins</Tab>
				</TabList>
				<RenderActions
					{...{ search, setSearch, status, setStatus, role, setRole, startDate, setStartDate, endDate, setEndDate, adminId, setAdminId }}
				/>
			</div>

			<TabPanel>
				<ActivityMonitor {...{ startDate, endDate, adminId }} />
			</TabPanel>
			<TabPanel>
				<Database {...{ search, status, role }} />
			</TabPanel>
		</Tabs>
	);
};

const RenderActions = ({
	search,
	setSearch,
	status,
	setStatus,
	role,
	setRole,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	adminId,
	setAdminId,
}) => {
	const dateFormat = "dd/MM/yyyy";

	const [searchParams] = useSearchParams();
	const currentTab = searchParams.get("tab") || "activity";

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
	const [name, setName] = useState("");

	const { data } = useQuery({
		queryKey: ["admin-name", name],
		queryFn: () => getAdminsByName(name),
		enabled: !!name,
	});

	const admins = data?.data?.map((admin) => ({ value: admin?.id?.toString(), label: `${admin?.firstName} ${admin?.lastName}` }));

	const { data: rawRoles } = useQuery({
		queryKey: ["adminRoles"],
		queryFn: getAdminRoles,
	});

	const roles = rawRoles?.data;

	return (
		<>
			<div className="flex flex-wrap items-center gap-4">
				{currentTab === "activity" && (
					<>
						<Popover>
							<PopoverTrigger asChild>
								<Button
									variant="outline"
									role="combobox"
									className={cn(
										"w-[200px] px-4 py-3 justify-between font-normal min-h-[3.8rem] text-gray-500 text-[1.4rem] rounded-lg",
										!adminId && "text-muted-foreground"
									)}
								>
									<span>{adminId ? admins?.find((admin) => admin.value === adminId)?.label : "Select admin"}</span>
									<PiCaretDown className="w-[14px] h-[14px] text-gray-600 ml-2 shrink-0" />
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-[200px] p-0">
								<Command>
									<CommandInput
										placeholder="Search admin..."
										className="h-9"
										value={name}
										onInput={(event) => setName(event.target.value)}
									/>
									<CommandList>
										<CommandEmpty>No admin found.</CommandEmpty>
										<CommandGroup>
											{admins?.map((admin) => (
												<CommandItem value={admin.label} key={admin.value} onSelect={() => setAdminId(admin.value)}>
													{admin.label}
													<CheckIcon
														className={cn("ml-auto h-4 w-4", admin.value === adminId ? "opacity-100" : "opacity-0")}
													/>
												</CommandItem>
											))}
										</CommandGroup>
									</CommandList>
								</Command>
							</PopoverContent>
						</Popover>
						<Popover>
							<PopoverTrigger asChild>
								<Button
									id="date"
									variant="outline"
									className="text-left font-normal text-gray-500 text-[1.5rem] rounded-lg px-4 py-3 uppercase"
								>
									{startDate ? format(startDate, dateFormat) : dateFormat} - {endDate ? format(endDate, dateFormat) : dateFormat}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0" align="start">
								<Calendar
									initialFocus
									mode="range"
									defaultMonth={startDate}
									selected={{ from: startDate, to: endDate }}
									onSelect={({ from, to }) => {
										setStartDate(from);
										setEndDate(to);
									}}
									numberOfMonths={2}
								/>
							</PopoverContent>
						</Popover>
					</>
				)}
				{currentTab !== "activity" && (
					<CustomInput
						icon={<SearchIcon className="w-[17px] h-[17px] text-gray-400" />}
						value={search}
						onChange={(event) => setSearch(event.target.value)}
						placeholder="Search..."
						innerClassName="!px-6 !py-3"
						className="!text-gray-500 placeholder:text-gray-400"
					/>
				)}
				{currentTab === "database" && (
					<Popover>
						<PopoverTrigger className="flex items-center gap-4 px-4 py-3 text-gray-500 bg-gray-100 border-gray-200 rounded-lg">
							<FilterIcon />
							Filter
						</PopoverTrigger>
						<PopoverContent className="w-[250px]" align="left">
							<Accordion className="grid gap-4" type="multiple" collapsible>
								<button
									className="ml-auto text-red-500 hover:underline text-[1.4rem]"
									onClick={() => {
										setStatus("");
										setRole("");
									}}
								>
									Reset
								</button>
								<AccordionItem value="item-1">
									<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">
										Status
									</AccordionTrigger>
									<AccordionContent className="grid gap-5 px-8 py-6">
										<CustomCheckbox
											label="ACTIVE"
											value="ACTIVE"
											name="status"
											type="radio"
											labelClassName="!text-[1.4rem]"
											checked={status === "ACTIVE"}
											onChange={(event) => setStatus(event.target.value)}
										/>
										<CustomCheckbox
											label="PENDING"
											value="PENDING"
											name="status"
											type="radio"
											labelClassName="!text-[1.4rem]"
											checked={status === "PENDING"}
											onChange={(event) => setStatus(event.target.value)}
										/>
										<CustomCheckbox
											label="INACTIVE"
											value="INACTIVE"
											name="status"
											type="radio"
											labelClassName="!text-[1.4rem]"
											checked={status === "INACTIVE"}
											onChange={(event) => setStatus(event.target.value)}
										/>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-2">
									<AccordionTrigger className="text-black !text-[1.5rem] !font-medium bg-gray-100 round-lg">Roles</AccordionTrigger>
									<AccordionContent className="grid gap-5 px-8 py-6">
										{roles?.map(({ id, name }, index) => (
											<CustomCheckbox
												label={name}
												value={id}
												name="role"
												type="radio"
												labelClassName="!text-[1.4rem]"
												checked={role === String(id)}
												key={index}
												onChange={(event) => setRole(event.target.value)}
											/>
										))}
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</PopoverContent>
					</Popover>
				)}
				{currentTab !== "activity" && (
					<>
						<Button
							variant="outline"
							className="!py-3 !px-7 ml-auto text-[1.4rem] w-fit text-gray-500 bg-gray-100 border-gray-200 rounded-lg"
							onClick={() => setIsRoleModalOpen(true)}
						>
							Add Role <AddIcon className="text-gray-500" />
						</Button>
						<Button className="!py-3 !px-7 ml-auto text-[1.4rem] w-fit" onClick={() => setIsModalOpen(true)}>
							Invite <AddIcon className="text-white" />
						</Button>
					</>
				)}
			</div>
			<Modal
				isOpen={isModalOpen}
				setIsOpen={setIsModalOpen}
				title={`Invite administrators`}
				subtitle="Create accounts and control access permissions by invites"
			>
				<InviteAdmin />
			</Modal>
			<Modal
				isOpen={isRoleModalOpen}
				setIsOpen={setIsRoleModalOpen}
				title={`Create Role`}
				subtitle="Create new role and access permissions for administrators"
			>
				<CreateRole />
			</Modal>
		</>
	);
};

const Database = ({ search: text, status, role }) => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;

	const { data, isLoading } = useQuery({
		queryKey: ["admins", page, text, role, status],
		queryFn: () => getAdmins({ page, text, role, status }),
	});

	const columns = [
		{
			name: "Staff name",
			cell: (row) => (
				<div className="flex items-center gap-3 overflow-hidden capitalize whitespace-nowrap">
					<div className="min-w-[32px] aspect-square rounded-xl bg-[#F3F4F6]" />
					{row.firstName} {row.lastName}
				</div>
			),
			grow: 1.4,
		},
		{
			name: "Role",
			cell: (row) => <div className="px-4 py-3 font-medium rounded-lg text-[#4B5563] bg-[#F3F4F6]">{row?.role?.name ?? "-"}</div>,
		},
		{ name: "Email", selector: (row) => row?.email },
		{ name: "Phone", selector: (row) => row.phone ?? "-", width: "170px" },
		{
			name: "Status",
			cell: (row) => <div className={`px-6 py-3 font-medium rounded-lg capitalize ${generateStatusClasses(row.status)}`}>{row.status}</div>,
			width: "150px",
		},
		{
			name: "",
			cell: (row) => <StaffActions row={row} />,
			width: "105px",
		},
	];
	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No available admins</p>
				</EmptyState>
			}
		/>
	);
};

const ActivityMonitor = ({ startDate, endDate, adminId }) => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;

	const columns = [
		{ name: "Date/Time", selector: (row) => format(new Date(row.createdAt), "dd MMM yyyy"), grow: 0.6 },
		{
			name: "Event",
			selector: (row) => getEndpointName(row?.action?.urlRoute, row?.action?.method?.toLowerCase()),
			grow: 2,
		},
		{
			name: "Staff name",
			cell: (row) => (
				<div className="flex items-center gap-3 overflow-hidden capitalize whitespace-nowrap">
					<div className="min-w-[32px] aspect-square rounded-xl bg-[#F3F4F6]" />
					{row?.admin?.firstName} {row?.admin?.lastName}
				</div>
			),
		},
		{
			name: "Initiator",
			cell: (row) => (
				<div className={`px-6 py-3 font-medium rounded-lg ${generateInitiatorClasses(row?.admin?.role?.name)}`}>{row?.admin?.role?.name}</div>
			),
		},
		// {
		// 	name: "",
		// 	cell: () => (
		// 		<button className="flex items-center gap-2 text-[#050505] font-medium text-[1.6rem]">
		// 			View <RightArrowHeadIcon />
		// 		</button>
		// 	),
		// 	width: "105px",
		// },
	];

	const { data, isLoading } = useQuery({
		queryKey: ["adminLogs", page, startDate, endDate, adminId],
		queryFn: () =>
			getAdminLogs({
				page,
				startDate: startDate ? startDate.toISOString() : null,
				endDate: endDate ? endDate.toISOString() : null,
				adminId,
			}),
	});

	const rows = data?.data;
	const count = data?.count;

	return (
		<CustomDataTable
			columns={columns}
			data={rows}
			paginationTotalRows={count}
			progressPending={isLoading}
			selectableRows
			noDataComponent={
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No activities</p>
				</EmptyState>
			}
		/>
	);
};

const StaffActions = ({ row }) => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const { mutate: statusMutate } = useMutation(changeAdminStatus, {
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["admins"] });
		},
		onError: (error) => error?.cause === 402 && setIsPasswordModalOpen(true),
	});
	const { mutate: inviteMutate } = useMutation(inviteAdmin, {
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
		},
		onError: (error) => error?.cause === 402 && setIsPasswordModalOpen(true),
	});

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger>
					<OptionsIcon className="w-[18px] h-[18px] text-gray-900" />
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-[150px]">
					<DropdownMenuLabel>Actions</DropdownMenuLabel>
					<DropdownMenuSeparator />
					{row?.status === "PENDING" ? (
						<DropdownMenuItem
							onClick={() =>
								renderConfirmDialogue().then(() => inviteMutate({ admins: [{ email: row?.email, roleId: row?.adminRoleId }] }))
							}
						>
							Re-Invite Admin
						</DropdownMenuItem>
					) : (
						<>
							{row?.status === "INACTIVE" ? (
								<DropdownMenuItem
									onClick={() => renderConfirmDialogue().then((res) => res && statusMutate({ id: row?.id, status: "ACTIVE" }))}
								>
									Make Active
								</DropdownMenuItem>
							) : (
								<DropdownMenuItem
									onClick={() => renderConfirmDialogue().then((res) => res && statusMutate({ id: row?.id, status: "INACTIVE" }))}
								>
									Make Inactive
								</DropdownMenuItem>
							)}
							<DropdownMenuItem onClick={() => setIsModalOpen(true)}>Change Staff Role</DropdownMenuItem>
						</>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title={`Change ${row?.firstName} ${row?.lastName} Role`} size="sm">
				<ChangeStaffRole row={row} />
			</Modal>
		</>
	);
};

export default Admins;
