import React from "react";
import { PaystackIcon, StripeIcon } from "src/assets/svg";
import { Switch } from "./ui/switch";
import { renderCurrency } from "src/utils/functions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeSubscriptionStatus } from "src/services/api";
import { useToast } from "./ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { useModalContext } from "./Modal";

const tenureKeys = {
	day: "day",
	week: "week",
	month: "month",
	year: "year",
	monthly: "month",
	quarterly: "3 months",
	biannually: "6 months",
	annually: "year",
};

const Plan = ({ subscription }) => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const { toast } = useToast();
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation(changeSubscriptionStatus, {
		onError: (error) => {
			if (error?.cause === 402) {
				setIsOpen(false);
				setIsPasswordModalOpen(true);
			}
		},
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["subscriptions"] });
		},
	});
	return (
		<div className="p-6 border border-gray-200 rounded-lg">
			<div className="flex items-center justify-between mb-5">
				<span className="p-4 border border-gray-300 rounded-lg">
					{subscription?.paymentProvider === "paystack" ? (
						<PaystackIcon className="w-[20px] h-[20px]" />
					) : (
						<StripeIcon className="w-[20px] h-[20px]" />
					)}
				</span>
				<Switch
					className="data-[state=checked]:bg-[#437EF7]"
					checked={subscription.status === "ONLINE"}
					disabled={isLoading}
					onCheckedChange={(checked) => mutate({ id: subscription?.id, status: checked ? "ONLINE" : "OFFLINE" })}
				/>
			</div>
			<p className="text-[#111827] text-[2rem] font-semibold mb-8">{subscription.name}</p>
			<p className="text-gray-400 text-[1.4rem]">
				<span className="text-[#111827] text-[3rem] font-semibold">{renderCurrency(subscription?.price / 100, subscription.currency)}</span>{" "}
				{subscription?.currency} / {tenureKeys[subscription.tenure]}
			</p>
			<div className="h-px my-8 bg-gray-200" />
			<div className="text-[1.6rem] text-[#6D7280]" dangerouslySetInnerHTML={{ __html: subscription.description }} />
		</div>
	);
};

export default Plan;
