import { Formik } from "formik";
import React, { useState } from "react";
import { CustomInput, Modal } from "src/components";
import * as yup from "yup";
import { BannerComponent } from "./layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import { signin } from "src/services/AuthAPI";
import { renderAlert } from "src/utils/functions";
import { useNavigate } from "react-router-dom";
import { ForgotPassword } from "src/modals";

const Login = () => {
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const validationSchema = yup.object({
		email: yup.string().email("Invalid email address").required("Email is required"),
		password: yup.string().required("Password is required"),
	});

	const { mutate, isLoading } = useMutation(signin, {
		onSuccess: (res) => {
			renderAlert({
				title: "Success",
				msg: res?.message,
				type: "success",
			}).then(() => {
				navigate("otp", { state: { token: res?.token } });
			});
		},
	});

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 shadow-[0_0px_24px_-4px_rgba(16,24,40,0.08)]  gap-[3rem] ">
			<BannerComponent />
			<div className="p-[5rem] w-[45rem] md:w-auto">
				<p className="text-[2.4rem] font-bold text-primary">Sign in</p>
				<Formik
					initialValues={{ email: "", password: "" }}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						mutate(values);
					}}
				>
					{({ values, handleChange, handleBlur, handleSubmit }) => (
						<form onSubmit={handleSubmit} className="my-[3rem]">
							<div className="mb-[3rem]">
								<CustomInput name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} placeholder="Email" />
							</div>
							<div className="mb-[3rem]">
								<CustomInput
									type="password"
									value={values.password}
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									placeholder="Password"
								/>
							</div>

							<button
								type="submit"
								disabled={isLoading}
								className="btn-loader bg-primary w-full text-white rounded-[6px] h-[4.4rem] py-[.8rem] px-[2rem] mt-[3rem]"
							>
								Continue
							</button>
						</form>
					)}
				</Formik>
				<div className="flex items-center justify-center">
					<button
						className="text-[1.4rem] text-center text-lightDark cursor-pointer hover:text-secondary"
						onClick={() => setIsModalOpen(true)}
					>
						Forgot Password?
					</button>
				</div>
			</div>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Forgot Password" size="sm">
				<ForgotPassword setIsModalOpen={setIsModalOpen} />
			</Modal>
		</div>
	);
};

export default Login;
