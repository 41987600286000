import { useQuery } from "@tanstack/react-query";
import React, { Suspense, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AddIcon, NoDataIcon, SearchIcon } from "src/assets/svg";
import { CustomInput, EmptyState, Loader, Modal, Plan } from "src/components";
import { Button } from "src/components/ui/button";
import { CreateSubscription } from "src/modals";
import { getSubscriptions } from "src/services/api";

const tabs = { online: 0, offline: 1 };

const Subscriptions = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState("");

	return (
		<div>
			<Tabs
				defaultIndex={searchParams.has("tab") ? tabs[searchParams.get("tab")] : 0}
				onSelect={(_, __, event) => setSearchParams({ tab: event.target.dataset.id })}
			>
				<div className="flex flex-wrap items-center justify-between gap-5 mb-10">
					<TabList style={{ margin: 0 }}>
						<Tab data-id="online">Online Subscriptions</Tab>
						<Tab data-id="offline">Offline Subscriptions</Tab>
					</TabList>
					<RenderActions
						{...{
							search,
							setSearch,
						}}
					/>
				</div>

				<Suspense fallback={<Loader />}>
					<TabPanel>
						<Plans {...{ search }} status="ONLINE" />
					</TabPanel>
					<TabPanel>
						<Plans {...{ search }} status="OFFLINE" />
					</TabPanel>
				</Suspense>
			</Tabs>
		</div>
	);
};

const RenderActions = ({ search, setSearch }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<div className="flex items-center gap-4">
				<CustomInput
					icon={<SearchIcon className="w-[17px] h-[17px] text-gray-400" />}
					value={search}
					onChange={(event) => setSearch(event.target.value)}
					placeholder="Search..."
					innerClassName="!px-6 !py-3"
					className="!text-gray-500 placeholder:text-gray-400"
				/>
				<Button className="!py-3 !px-7 ml-auto text-[1.4rem] w-fit" onClick={() => setIsModalOpen(true)}>
					Add new plan <AddIcon className="text-white" />
				</Button>
			</div>
			<Modal
				isOpen={isModalOpen}
				setIsOpen={setIsModalOpen}
				title={`Create subscription plan`}
				subtitle="Configure subscription prices and offerings"
			>
				<CreateSubscription />
			</Modal>
		</>
	);
};

const Plans = ({ search, status }) => {
	const { data } = useQuery({
		queryKey: ["subscriptions", status, search],
		queryFn: () => getSubscriptions({ name: search, status }),
		suspense: true,
	});

	const subscriptions = data?.data;

	if (!subscriptions.length)
		return (
			<div>
				<EmptyState icon={<NoDataIcon />}>
					<p className="text-[2rem] text-[#1F2937] font-semibold">No subscriptions</p>
				</EmptyState>
			</div>
		);

	return (
		<div className="grid gap-[20px] grid-cols-[repeat(auto-fill,minmax(250px,1fr))]">
			{subscriptions?.map((subscription, index) => (
				<Plan subscription={subscription} key={index} />
			))}
		</div>
	);
};

export default Subscriptions;
