import React from "react";
import { Formik } from "formik";
import { CustomInput } from "src/components";
import * as yup from "yup";
import { BannerComponent } from "./layout/AuthLayout";
import { confirmOTP } from "src/services/AuthAPI";
import { useMutation } from "@tanstack/react-query";
import { renderAlert } from "src/utils/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { useLocation } from "react-router-dom";

const OTP = () => {
	const { setUser, setToken } = useProtectedRoutesContext();
	let { state } = useLocation();

	const validationSchema = yup.object({
		otp: yup.string().required("otp is required"),
	});

	const { mutate, isLoading } = useMutation(confirmOTP, {
		onSuccess: (res) => {
			setUser(res?.user);
			setToken(res?.token);
		},
		onError: (err) => {
			// renderToastMsg(err?.error,);
		},
	});
	const { mutate: mutateResendOTP, isLoading: isLoadingOTP } = useMutation(confirmOTP, {
		onSuccess: (res) => {
			renderAlert({
				title: "Success",
				msg: res?.message,
				type: "success",
			});
		},
	});

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 shadow-[0_0px_24px_-4px_rgba(16,24,40,0.08)]  gap-[3rem] ">
			<BannerComponent />
			<div className="p-[5rem] w-[45rem] md:w-auto">
				<p className="text-[2.4rem] font-bold text-primary mb-3">Verification required</p>
				<p className="text-[1.4rem] font-normal text-gray">Enter the code sent to your email</p>
				<Formik
					initialValues={{ otp: "" }}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						const authToken = state?.token;
						mutate({ token: authToken, otp: values?.otp });
					}}
				>
					{({ values, handleChange, handleBlur, handleSubmit }) => (
						<form onSubmit={handleSubmit} className="my-[3rem] ">
							<div className="mb-[3rem]">
								<CustomInput name="otp" value={values.otp} onBlur={handleBlur} onChange={handleChange} placeholder="OTP" />
							</div>
							<label className="flex items-center justify-start gap-2 cursor-pointer mb-[3rem]">
								<input
									type="checkbox"
									name="isChecked"
									className="cursor-pointer accent-gray focus:accent-primary hover:accent-primary"
									checked={values.isChecked}
								/>

								<div className="text-gray text-[1.4rem] font-normal leading-none">Keep me signed in</div>
							</label>

							<button
								type="submit"
								disabled={isLoading || isLoadingOTP}
								className={`${
									isLoading ? "btn-loader" : ""
								} bg-primary w-full text-white rounded-[6px] h-[4.4rem] py-[.8rem] px-[2rem] mt-[3rem]`}
							>
								Sign in
							</button>
						</form>
					)}
				</Formik>
				<button
					type="button"
					disabled={isLoadingOTP}
					className=" flex justify-center items-center w-full btn-loader-outline text-[1.4rem] text-center text-lightDark cursor-pointer"
					onClick={() => {
						mutateResendOTP({ token: state?.token });
					}}
				>
					I didn’t get the code
				</button>
			</div>
		</div>
	);
};

export default OTP;
