import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import Toolbar from "./Toolbar";

import "./tiptap.module.css";

const Tiptap = ({ description = "", onChange }) => {
	const editor = useEditor({
		extensions: [
			StarterKit.configure({}),
			Heading.configure({
				HTMLAttributes: {
					class: "text-[1.4rem] font-semibold text-neutral-900",
					levels: [1],
				},
			}),
			Underline.configure({}),
			Link.configure({
				openOnClick: false,
				autolink: true,
				HTMLAttributes: {
					// Change rel to different value
					// Allow search engines to follow links(remove nofollow)
					rel: "noopener noreferrer",
					// Remove target entirely so links open in a new tab
					target: "_black",
				},
			}),
			Text,
			BulletList.configure({
				HTMLAttributes: {
					class: "list-disc ml-3",
				},
			}),
			OrderedList.configure({
				HTMLAttributes: {
					class: "list-decimal ml-3",
				},
			}),
			TextStyle,
			Document,
			Paragraph.configure({
				HTMLAttributes: {
					class: "text-black text-[1.4rem] font-medium",
				},
			}),

			TextAlign.configure({
				types: ["heading", "paragraph"],
			}),
			Highlight,

			ListItem,
			Color,
		],
		content: description,
		editorProps: {
			attributes: {
				class: "px-4 py-2 rounded-md min-h-[150px] focus-visible:outline-transparent outline-transparent",
			},
		},
		onUpdate({ editor }) {
			onChange(editor.getHTML());
			// console.log(editor.getHTML())
		},
	});

	return (
		<div className="overflow-hidden border rounded-md">
			<Toolbar editor={editor} />
			<EditorContent editor={editor} />
		</div>
	);
};

export default Tiptap;
