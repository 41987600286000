import React from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useMatches } from "react-router-dom";
import { BurgerIcon, UserIcon } from "src/assets/svg";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const TopNav = () => {
	const { toggleSidebar } = useProSidebar();
	const matches = useMatches();

	const header = matches.filter((match) => Boolean(match.handle?.header))?.at(0)?.handle?.header;

	return (
		<div className="flex items-center justify-between px-10 py-8 border-b border-lightGray">
			<h1 className="text-[2rem] text-black font-semibold">{header}</h1>
			<div className="flex items-center gap-3">
				<Profile />
				<button className="p-3 min-[1000px]:hidden" onClick={() => toggleSidebar()}>
					<BurgerIcon className="w-[20px] h-[20px]" />
				</button>
			</div>
		</div>
	);
};

export const Profile = () => {
	const { user } = useProtectedRoutesContext();

	const logOut = () => {
		localStorage.clear();
		window.location.reload();
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger className="flex w-full gap-4">
				<UserIcon />
				<div className="text-left">
					<p className="text-[#050505] text-[1.4rem] font-medium">
						{user?.firstName} {user?.lastName}
					</p>
					<p className="text-[#9CA3AF] text-[1.2rem] font-medium uppercase">{user?.role.name}</p>
				</div>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-[200px]">
				<DropdownMenuLabel className="text-[1.2rem]">Logout</DropdownMenuLabel>
				<DropdownMenuItem onClick={logOut}>Logout</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default TopNav;
