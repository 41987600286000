import React from "react";
import { DeleteIcon, EditIcon, EyeIcon, NoDataIcon, User2Icon } from "src/assets/svg";
import { CustomDataTable, EmptyState } from "src/components";
import { PiDotsThreeBold, PiPlusBold } from "react-icons/pi";
import { tickets } from "src/utils/constants";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { Button } from "src/components/ui/button";
const generateInitiatorClasses = (initiator) =>
	initiator === "pending"
		? "text-[#E2341D] bg-[#FFF2F0]"
		: initiator === "resolved"
		? "text-[#2D8A39] bg-[#F0FDF4]"
		: "text-[#EEA23E] bg-[#FFF8EB]";

const Tickets = () => {
	const columns = [
		{
			name: "Date and Time",
			cell: (row) => <div className="flex items-center gap-3 overflow-hidden whitespace-nowrap">{row?.dateTime}</div>,
			grow: 1.4,
		},
		{
			name: "Assigned",
			selector: (row) => <div className="px-6 py-3 rounded-[.6rem] text-[#4B5563] bg-[#F3F4F6]">{row?.assignedTo}</div>,
			grow: 1,
		},
		{
			name: "Class",
			cell: (row) => <div className={`px-6 py-3 font-medium rounded-lg `}>{row.class}</div>,
			width: "150px",
		},
		{ name: "Customer", selector: (row) => row?.customer?.name },
		{ name: "Issue", selector: (row) => row?.issue, grow: 2 },
		{
			name: "Status",
			selector: (row) => (
				<div className={`${generateInitiatorClasses(row.status.toLowerCase())} px-[1.6rem] py-[.8rem] rounded-[10rem]`}>{row?.status}</div>
			),
		},
		{
			name: "",
			cell: () => (
				<>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost">
								<PiDotsThreeBold className="text-gray" size="3rem" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="w-[19rem]">
							<DropdownMenuGroup className="font-normal text-gray">
								<DropdownMenuItem className="flex gap-[1rem]  my-[.5rem] ">
									<EyeIcon className=" text-gray" />
									View Ticket
								</DropdownMenuItem>
								<DropdownMenuItem className="flex gap-[1rem]  my-[.5rem]">
									<User2Icon className=" text-gray" />
									Assign Admin
								</DropdownMenuItem>
								<DropdownMenuItem className="flex gap-[1rem]  my-[.5rem]">
									<EditIcon className=" text-gray h-[1.6rem] w-[1.6rem]" />
									Edit Ticket
								</DropdownMenuItem>
								<DropdownMenuItem className="flex gap-[1rem]  my-[.5rem] text-[#EF4444] hover:text-[#EF4444]">
									<DeleteIcon size="1.6rem" className="text-[#EF4444]  h-[1.6rem] w-[1.6rem]" />
									Delete Ticket
								</DropdownMenuItem>
							</DropdownMenuGroup>
						</DropdownMenuContent>
					</DropdownMenu>
				</>
			),
			width: "105px",
		},
	];

	return (
		<div className="my-[2rem]">
			<div className="flex justify-end my-[3rem] ">
				<button
					type="button"
					className=" flex justify-center items-center gap-[1rem] text-[1.4rem] font-medium border border-lightGray rounded-[.5rem] px-[1.2rem] py-[.8rem]"
				>
					<PiPlusBold size="1.6rem" /> Add Ticket
				</button>
			</div>

			<CustomDataTable
				columns={columns}
				data={tickets}
				selectableRows
				noDataComponent={
					<EmptyState icon={<NoDataIcon />}>
						<p className="text-[2rem] text-[#1F2937] font-semibold">No activities</p>
					</EmptyState>
				}
			/>
		</div>
	);
};

export default Tickets;
