import React, { createContext, useContext } from "react";
import { Suspense } from "react";
import ReactModal from "react-modal";
import Loader from "./Loader";
import { CloseIcon } from "src/assets/svg";

const ModalContext = createContext({
	isOpen: false,
	setIsOpen: () => {},
	onClose: () => {},
});

export const useModalContext = () => useContext(ModalContext);

const Modal = ({ size, children, isOpen, setIsOpen, title, subtitle, onClose = () => {}, ...rest }) => {
	let modalClass;
	if (size === "xl") {
		modalClass = "modal-xlg";
	} else if (size === "l") {
		modalClass = "modal-lg";
	} else if (size === "normal") {
		modalClass = "modal-normal";
	} else if (size === "sm") {
		modalClass = "modal-sm";
	} else if (size === "xsm") {
		modalClass = "modal-xsm";
	} else {
		modalClass = "modal-normal";
	}
	return (
		<ReactModal isOpen={isOpen} ariaHideApp={false} {...rest}>
			<ModalContext.Provider value={{ isOpen, setIsOpen, onClose }}>
				<div className={`modal-dialog ${modalClass}`} role="document">
					<div className="modal-content app-modal-content">
						<div className="modal-header">
							<h4 className="text-black text-[2rem] font-semibold">{title}</h4>

							<button
								type="button"
								className="p-4 bg-gray-200 rounded-lg close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setIsOpen(false);
									onClose();
								}}
							>
								<CloseIcon className="w-8 h-8" />
							</button>
						</div>
						<div className="modal-body rec-modal-body">
							{!!subtitle && <h6 className="text-[1.4rem] mb-12 text-gray-600">{subtitle}</h6>}
							<Suspense fallback={<Loader />}>{children}</Suspense>
						</div>
					</div>
				</div>
			</ModalContext.Provider>
		</ReactModal>
	);
};

export default Modal;
