import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import TopNav from "./TopNav";
import styled from "styled-components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { Toaster } from "src/components/ui/toaster";

const Container = styled.div`
	display: flex;
	overflow: hidden;

	.wrapper {
		max-height: 100vh;
		min-height: 100vh;
		flex: 1;
		width: calc(100% - 260px);
		background-color: #ffffff;
		overflow-y: scroll;

		& > div {
			padding-left: 4rem;
			padding-right: 4rem;
		}

		.main {
			padding-top: 2rem;
		}
	}

	@media only screen and (max-width: 769px) {
		.wrapper {
			min-height: 100vh;
			flex: 1;
			width: 100%;

			& > div {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}
`;

const MainLayout = () => {
	const { user, token } = useProtectedRoutesContext();

	if (!user || !token) return <Navigate to={{ pathname: "/auth" }} />;

	return (
		<Container>
			<SideBar />
			<div className="wrapper">
				<TopNav />
				<div className="main">
					<Suspense fallback={<></>}>
						<Outlet />
						<Toaster />
					</Suspense>
				</div>
			</div>
		</Container>
	);
};

export default MainLayout;
