import React from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import { LeftArrowIcon, RightArrowIcon } from "src/assets/svg";

const Pagination = ({ rowCount }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const rowsPerPage = parseInt(searchParams.get("per_page")) || 15;

	const pageCount = Math.ceil(rowCount / rowsPerPage);

	const handlePageClick = ({ selected }) => {
		setSearchParams((prev) => ({ ...prev, page: selected + 1 }));
	};

	return (
		<div className="flex mt-28">
			<ReactPaginate
				breakLabel="..."
				previousLabel={
					<p className="text-[#1F1D1D] hover:text-primary font-medium text-[1.4rem] flex gap-3 items-center">
						<LeftArrowIcon /> Prev
					</p>
				}
				nextLabel={
					<p className="text-[#1F1D1D] hover:text-primary font-medium text-[1.4rem] flex gap-3 items-center">
						Next <RightArrowIcon />
					</p>
				}
				onPageChange={handlePageClick}
				pageRangeDisplayed={2}
				marginPagesDisplayed={2}
				pageCount={pageCount}
				renderOnZeroPageCount={null}
				forcePage={searchParams.has("page") ? parseInt(searchParams.get("page")) - 1 : 0}
				containerClassName="custom-pagination"
				pageClassName="custom-pagination-page"
				previousClassName="custom-pagination-previous mr-auto"
				nextClassName="custom-pagination-next ml-auto"
			/>
		</div>
	);
};

export default Pagination;
