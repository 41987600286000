import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { CustomTextArea } from "src/components";
import { useModalContext } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { useToast } from "src/components/ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { changeSMBStatus } from "src/services/api";
import * as Yup from "yup";

const DeleteSMB = ({ row }) => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();
	const { setIsOpen } = useModalContext();

	const initialValues = {
		id: row?.id,
		status: "FLAGGEDOFFLINE",
		reason: "",
	};
	const validationSchema = Yup.object().shape({
		reason: Yup.string().required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(changeSMBStatus, {
		onSuccess: ({ message }) => {
			queryClient.refetchQueries({ queryKey: ["smbs"] });
			toast({ title: "Success", description: message });
			setIsOpen(false);
		},
		onError: (error) => {
			if (error?.cause === 402) {
				setIsOpen(false);
				setIsPasswordModalOpen(true);
			}
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-7">
					<CustomTextArea name="reason" value={values.reason} onChange={handleChange} label="Reason" />
					<Button className="flex items-center gap-4 rounded-lg text-[1.4rem] px-6 py-5" disabled={isLoading}>
						Delete SMB
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default DeleteSMB;
