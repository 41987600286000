import React, { useState } from "react";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { format } from "date-fns";
import { useQueries } from "@tanstack/react-query";
import { Label, Line, LineChart, PieChart, Pie, XAxis, YAxis, AreaChart, Area, CartesianGrid } from "recharts";
import { EmptyState, StatCard } from "src/components";
import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from "src/components//ui/chart";
import { getRegionAnalytics, getSMBAnalytics, getSMBGraph, getWeeklyAnalytics } from "src/services/api";
import { NoDataIcon } from "src/assets/svg";
import { renderCurrency } from "src/utils/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { Spinner } from "src/components/Loader";

const Dashboard = () => {
	const { user } = useProtectedRoutesContext();

	const dateFormat = "dd/MM/yyyy";
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [peakStartDate, setPeakStartDate] = useState(null);
	const [peakEndDate, setPeakEndDate] = useState(null);

	const [
		{ data: smbCountAnalytics, isLoading: smbCountAnalyticsLoading },
		{ data: weeklyAnalytics, isLoading: weeklyAnalyticsLoading },
		{ data: smallWeeklyAnalytics, isLoading: smallWeeklyAnalyticsLoading },
		{ data: regionAnalytics, isLoading: regionAnalyticsLoading },
		{ data: smbAnalytics, isLoading: smbAnalyticsLoading },
	] = useQueries({
		queries: [
			{ queryKey: ["smb-analytics"], queryFn: getSMBAnalytics },
			{
				queryKey: ["weekly-analytics", peakStartDate, peakEndDate],
				queryFn: () =>
					getWeeklyAnalytics({
						startDate: peakStartDate ? format(peakStartDate, "dd/MM/yyyy") : null,
						endDate: peakEndDate ? format(peakEndDate, "dd/MM/yyyy") : null,
					}),
			},
			{
				queryKey: ["small-weekly-analytics", startDate, endDate],
				queryFn: () =>
					getWeeklyAnalytics({
						startDate: startDate ? format(startDate, "dd/MM/yyyy") : null,
						endDate: endDate ? format(endDate, "dd/MM/yyyy") : null,
					}),
			},
			{
				queryKey: ["region-analytics", startDate, endDate],
				queryFn: () =>
					getRegionAnalytics({
						startDate: startDate ? format(startDate, "dd/MM/yyyy") : null,
						endDate: endDate ? format(endDate, "dd/MM/yyyy") : null,
					}),
			},
			{
				queryKey: ["smb-graph", startDate, endDate],
				queryFn: () =>
					getSMBGraph({
						startDate: startDate ? format(startDate, "dd/MM/yyyy") : null,
						endDate: endDate ? format(endDate, "dd/MM/yyyy") : null,
					}),
			},
		],
	});

	const colors = [
		"#F97316",
		"#FDBA74",
		"#E5E7EB",
		"hsl(var(--chart-1))",
		"hsl(var(--chart-2))",
		"hsl(var(--chart-3))",
		"hsl(var(--chart-4))",
		"hsl(var(--chart-5))",
	];
	const regionData = regionAnalytics?.data?.map((e, i) => ({
		country: e?.country,
		price: e["_sum"]?.price,
		fill: colors[i % colors.length],
	}));

	const regionConfig = regionAnalytics?.data?.reduce((config, item, index) => {
		config[item.country] = {
			label: item.country,
			color: colors[index % colors.length],
		};
		return config;
	}, {});

	//* PEAK PERIODS (ORDERS), TRANSACTIONS VOLUME chart
	const weeklyData = weeklyAnalytics?.data?.map((e) => ({
		week: format(new Date(e?.week), "MMM"),
		amount: e?.average_amount,
	}));
	const transactionVolume = smallWeeklyAnalytics?.data?.reduce((sum, current) => sum + current?.total_amount, 0);
	const smallWeeklyData = smallWeeklyAnalytics?.data?.map((e) => ({
		week: format(new Date(e?.week), "MMM"),
		amount: e?.average_amount,
	}));

	const weeklyConfig = {
		desktop: {
			label: "amount",
			color: "hsl(var(--chart-1))",
		},
	};

	//* PEAK PERIODS (ORDERS), TRANSACTIONS VOLUME chart
	const smbData = smbAnalytics?.data?.map((e) => ({
		week: format(new Date(e?.week), "MMM"),
		count: e?.business_count,
	}));

	return (
		<>
			<div className="flex justify-between flex-wrap gap-x-[3rem] gap-y-6">
				<p className="text-gray text-[2rem]">
					Hello, <span className="text-black">{user?.firstName} 👋,</span>
				</p>
				<Popover>
					<PopoverTrigger asChild>
						<Button
							id="date"
							variant="outline"
							className="text-left font-normal text-gray-500 text-[1.4rem] rounded-lg px-4 py-3 uppercase"
						>
							{startDate ? format(startDate, dateFormat) : dateFormat} - {endDate ? format(endDate, dateFormat) : dateFormat}
						</Button>
					</PopoverTrigger>

					<PopoverContent className="w-auto p-0" align="start">
						<Calendar
							initialFocus
							mode="range"
							defaultMonth={startDate}
							selected={{ from: startDate, to: endDate }}
							onSelect={({ from, to }) => {
								setStartDate(from);
								setEndDate(to);
							}}
							numberOfMonths={2}
						/>
					</PopoverContent>
				</Popover>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-[3rem] my-[3rem]">
				<div className="lg:col-span-2">
					<div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-[3rem]">
						<CustomCard
							isLoading={smbCountAnalyticsLoading || smbAnalyticsLoading}
							title="TOTAL SMB CLIENTS"
							count={smbCountAnalytics?.data?.totalCount}
							lineData={smbData}
						/>
						<CustomCard
							isLoading={smallWeeklyAnalyticsLoading}
							title="TRANSACTIONS SUM"
							count={renderCurrency(transactionVolume)}
							lineData={smallWeeklyData}
						/>
						<StatCard isLoading={smbCountAnalyticsLoading} title="Total Subscribed" amount={smbCountAnalytics?.data?.subscribedCount} />
						<StatCard
							isLoading={smbCountAnalyticsLoading}
							title="Pending Verification"
							amount={smbCountAnalytics?.data?.pendingVerificationCount}
						/>
					</div>
				</div>

				<div className="p-[1.6rem] flex flex-col justify-between border border-lightGray rounded-lg">
					<p className="text-[#05050580] text-[1.2rem] font-medium mb-3 uppercase">CUSTOMER REGIONS</p>
					<div className="my-[1rem]">
						{regionAnalyticsLoading ? (
							<Spinner />
						) : (
							<ChartContainer config={regionConfig} className="mx-auto">
								<PieChart>
									<ChartTooltip className="text-[1rem] gap-[1rem]" cursor={false} content={<ChartTooltipContent hideLabel />} />

									<Pie
										data={regionData}
										dataKey="price"
										nameKey="country"
										innerRadius={65}
										strokeWidth={5}
										startAngle={-45}
										endAngle={225}
										outerRadius={80}
									>
										<Label
											content={({ viewBox }) => {
												if (viewBox && "cx" in viewBox && "cy" in viewBox) {
													return (
														<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
															<span
																x={viewBox.cx}
																y={(viewBox.cy || 0) - 15}
																className="fill-muted-foreground text-[1.2rem]"
															>
																CUSTOMERS
															</span>
															<span
																x={viewBox.cx}
																y={(viewBox.cy || 0) + 14}
																className="fill-foreground text-[2rem] font-bold"
															>
																{regionAnalytics?.data?.length.toLocaleString()}
															</span>
														</text>
													);
												}
											}}
										/>
									</Pie>
									<ChartLegend className="text-[1.2rem]" content={<ChartLegendContent />} />
								</PieChart>
							</ChartContainer>
						)}
					</div>
				</div>
			</div>

			<div className="p-[1.6rem] border border-lightGray rounded-lg">
				<div className="flex justify-between items-center gap-[2rem]  ">
					<p className="text-[#05050580] text-[1.2rem]">PEAK PERIODS (ORDERS)</p>
					<Popover>
						<PopoverTrigger asChild>
							<Button
								id="date"
								variant="outline"
								className="text-left font-normal text-gray-500 text-[1.4rem] rounded-lg px-4 py-3 uppercase"
							>
								{peakStartDate ? format(peakStartDate, dateFormat) : dateFormat} -{" "}
								{peakEndDate ? format(peakEndDate, dateFormat) : dateFormat}
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								initialFocus
								mode="range"
								defaultMonth={peakStartDate}
								selected={{ from: peakStartDate, to: peakEndDate }}
								onSelect={({ from, to }) => {
									setPeakStartDate(from);
									setPeakEndDate(to);
								}}
								numberOfMonths={2}
							/>
						</PopoverContent>
					</Popover>
				</div>

				{weeklyAnalyticsLoading ? (
					<div className="min-h-[200px] flex justify-center items-center">
						<Spinner />
					</div>
				) : weeklyData?.length > 0 ? (
					<ChartContainer className="mt-[5rem] text-[1.2rem]" config={weeklyConfig}>
						<AreaChart accessibilityLayer data={weeklyData}>
							<CartesianGrid vertical={false} />
							<XAxis dataKey="month" tickLine={false} axisLine={false} tickMargin={8} tickFormatter={(value) => value} />
							<YAxis dataKey="amount" tickLine={false} axisLine={false} tickMargin={10} />
							<ChartTooltip
								className="text-[1rem] gap-[1rem]"
								cursor={false}
								content={<ChartTooltipContent indicator="dot" hideLabel />}
							/>
							<Area dataKey="amount" type="linear" fill="var(--color-desktop)" fillOpacity={0.4} stroke="var(--color-desktop)" />
						</AreaChart>
					</ChartContainer>
				) : (
					<EmptyState icon={<NoDataIcon />}>
						<p className="text-[2rem] text-[#1F2937] font-semibold">No orders</p>
					</EmptyState>
				)}
			</div>
		</>
	);
};

const CustomCard = ({ title, count, lineData, isLoading }) => {
	const value = lineData?.length > 0 ? Object?.keys(lineData[0])[1] : "";
	const chartConfig = {
		desktop: {
			label: "Desktop",
			color: "hsl(var(--chart-1))",
		},
	};

	if (isLoading)
		return (
			<div className="p-[1.6rem] border border-lightGray rounded-lg">
				<Spinner />
			</div>
		);

	return (
		<div className="p-[1.6rem] border border-lightGray rounded-lg">
			<div className="flex justify-between h-[10rem] gap-[1rem]">
				<div>
					<p className="text-[#05050580] text-[1.2rem] font-medium mb-3 uppercase">{title}</p>
					<p className="text-[#050505] text-[2.4rem] font-medium">{count}</p>
				</div>

				{lineData?.length > 0 && (
					<ChartContainer config={chartConfig}>
						<LineChart accessibilityLayer data={lineData} width={100}>
							<Line
								dataKey={value}
								// type="natural"
								stroke="var(--color-desktop)"
								strokeWidth={2}
								dot={false}
							/>
						</LineChart>
					</ChartContainer>
				)}
			</div>
		</div>
	);
};

export default Dashboard;
