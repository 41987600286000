import axios from "axios";
import { getFromStorage, removeFalsyElement, renderToastMsg } from "src/utils/functions";

const requestUrl = () => {
	var url = window.origin;
	if (url?.includes("http://localhost")) {
		return process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`;
	} else {
		return process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL_LIVE}` : `${process.env.REACT_APP_PROD_BASEURL_LIVE}`;
	}
};

const api = axios.create({
	baseURL: requestUrl(),
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use((config) => {
	config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
	return config;
}, null);

api.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response.status === 401) {
			renderToastMsg(error.response.data.error).then(() => {
				localStorage.clear();
				window.location.reload();
			});
		} else {
			if (error.response.data.error) {
				renderToastMsg(error.response.data.error, "error");
			} else {
				renderToastMsg(error.response.data.error, "error");
			}
			throw new Error(error.response.data.error, {
				cause: error.response.status,
			});
		}
	}
);

export const getAdminRoles = () => api.get("/auth/roles");

export const createAdminRoles = (data) => api.post("/auth/roles", data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const getAdmins = (params) => api.get("/auth", { params: removeFalsyElement(params) });

export const getAdminsByName = (name) => api.get(`/auth/${name}/name`);

export const changeAdminStatus = ({ id, ...data }) =>
	api.post(`/auth/${id}`, data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const changeAdminRole = ({ id, ...data }) =>
	api.patch(`/auth/${id}`, data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const inviteAdmin = (data) => api.post(`/auth/invite`, data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const passwordToken = (data) => api.post(`/auth/password-token`, data);

export const getSMBAnalytics = () => api.get("/admin/smb/analytics");

export const getSMBs = (params) => api.get("/admin/smb", { params: removeFalsyElement(params) });

export const getSMBsByName = (name) => api.get(`/admin/smb/${name}/name`);

export const getOrders = (params) => api.get("/admin/orders", { params: removeFalsyElement(params) });

export const getTransactions = (params) => api.get("/admin/transactions", { params: removeFalsyElement(params) });

export const getOrderAnalytics = () => api.get("/admin/orders/analytics");

export const resetKYBAttempts = ({ id }) =>
	api.patch(`/admin/smb/${id}`, {}, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const suspensionToggle = ({ id, ...data }) =>
	api.put(`/admin/smb/${id}`, data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const changeSMBStatus = ({ id, ...data }) =>
	api.post(`/admin/smb/${id}`, data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const getBusinessLogs = (params) => api.get("/admin/logs/business", { params: removeFalsyElement(params) });

export const getAdminLogs = (params) => api.get("/admin/logs/admin", { params: removeFalsyElement(params) });

export const getSettings = (params = {}) => api.get("/admin/settings/grouped", { params: removeFalsyElement(params) });

export const updateSetting = (data) => api.post("/admin/settings", data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const getWeeklyAnalytics = (params) => api.get("admin/analytics/weekly", { params });

export const getRegionAnalytics = (params) => api.get("admin/analytics/sales", { params });

export const getSMBGraph = (params) => api.get("admin/analytics/smb", { params });

export const getSubscriptions = (params) => api.get("/subscriptions", { params });

export const createSubscription = (data) =>
	api.post("/subscriptions", data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const changeSubscriptionStatus = ({ id, ...data }) =>
	api.patch(`/subscriptions/${id}/status`, data, { headers: { "Password-Authorization": getFromStorage("password-token") } });

export const getCountries = () => api.get("/assets/countries");

export const getCurrencies = () => api.get("/assets/currencies");

export const getPaymentProviders = () => api.get("/assets/payment-processors");
