import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { CustomInput, CustomMultiSelect, CustomSelect } from "src/components";
import Tiptap from "src/components/Editor";
import { useModalContext } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { useToast } from "src/components/ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { createSubscription, getCountries, getCurrencies, getPaymentProviders } from "src/services/api";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Subscription name is required"),
	paymentProvider: Yup.string()
		.required("Subscription provider is required")
		.oneOf(["paystack", "stripe"], "Subscription must be one of paystack or stripe"),
	countriesApplied: Yup.array()
		.of(Yup.string().required("This field is required"))
		.min(1, "You must provide at least 1 country")
		.required("Countries subscription applies to is required"),
	currency: Yup.string().required("Subscription currency is required"),
	status: Yup.string().required().oneOf(["ONLINE", "OFFLINE"], "Invalid status provided"),
	price: Yup.number().positive("Subscription price must be positive").required("Subscription price is required"),
	discountApplied: Yup.number().min(0, "Discount must be greater than zero").max(100, "Discount must be less than 100").nullable(),
	tenure: Yup.string()
		.required("Subscription tenure is required")
		.when("paymentProvider", {
			is: "stripe",
			then: (schema) => schema.oneOf(["day", "week", "month", "year"], "Tenure provided isn't supported by stripe"),
			otherwise: (schema) => schema.oneOf(["monthly", "quarterly", "biannually", "annually"], "Tenure provided isn't supported by paystack"),
		}),
	tenure_interval: Yup.number().when("paymentProvider", {
		is: "stripe",
		then: (schema) => schema.integer("Tenure interval must be an integer").required("Tenure interval is required"),
		otherwise: (schema) =>
			schema.test({
				name: "must-be-null",
				message: "Please do not provide a tenure interval for paystack plan",
				test: (value) => !value,
			}),
	}),
	description: Yup.string().required("Subscription description is required"),
	extraCharges: Yup.object().shape({
		EMAIL: Yup.number().required("This field is required"),
		SMS: Yup.number().required("This field is required"),
		WHATSAPP: Yup.number().required("This field is required"),
	}),
	limits: Yup.object().shape({
		EMAIL: Yup.number().integer().required("This field is required"),
		SMS: Yup.number().integer().required("This field is required"),
		WHATSAPP: Yup.number().integer().required("This field is required"),
	}),
});
const initialValues = {
	name: "",
	paymentProvider: "",
	status: "ONLINE",
	countriesApplied: [],
	currency: "",
	price: 0,
	discountApplied: 0,
	tenure: "",
	tenure_interval: 1,
	description: "",
	extraCharges: { EMAIL: 0, SMS: 0, WHATSAPP: 0 },
	limits: { EMAIL: 0, SMS: 0, WHATSAPP: 0 },
};
const returnTenureValues = (processor) =>
	processor === "paystack" ? ["monthly", "quarterly", "biannually", "annually"] : ["day", "week", "month", "year"];

const CreateSubscription = () => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const { toast } = useToast();
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const [{ data: rawCountries }, { data: rawCurrencies }, { data: rawProviders }] = useQueries({
		queries: [
			{
				queryKey: ["countries"],
				queryFn: getCountries,
				suspense: true,
			},
			{
				queryKey: ["currencies"],
				queryFn: getCurrencies,
				suspense: true,
			},
			{
				queryKey: ["payment-processors"],
				queryFn: getPaymentProviders,
				suspense: true,
			},
		],
	});

	const { mutate, isLoading } = useMutation(createSubscription, {
		onError: (error) => {
			if (error?.cause === 402) {
				setIsPasswordModalOpen(true);
			}
		},
		onSuccess: ({ message }) => {
			setIsOpen(false);
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["subscriptions"] });
		},
	});

	const countries = rawCountries?.data;
	const currencies = rawCurrencies?.data;
	const paymentProviders = rawProviders?.data;

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="grid gap-6">
					<HandleTenureChange />
					<CustomInput
						value={values.name}
						onChange={handleChange}
						name="name"
						placeholder="Plan name"
						label="Plan name"
						innerClassName="!px-6 !py-3"
						className="!text-gray-500 placeholder:text-gray-400"
					/>
					<div className="grid gap-6 grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
						<CustomInput
							value={values.price}
							onChange={handleChange}
							name="price"
							inputType="numeric"
							type="number"
							placeholder="Plan price"
							label="Plan price"
							innerClassName="!px-6 !py-3"
							className="!text-gray-500 placeholder:text-gray-400"
						/>
						<CustomSelect
							name="currency"
							label="Plan currency"
							placeholder="Select a currency"
							defaultValue={values.currency}
							onValueChange={(value) => setFieldValue("currency", value)}
							options={currencies?.map(({ code, name }) => ({ value: code, label: name }))}
						/>
						<CustomSelect
							name="paymentProvider"
							label="Plan provider"
							placeholder="Select a provider"
							defaultValue={values.paymentProvider}
							value={values.paymentProvider}
							onValueChange={(value) => setFieldValue("paymentProvider", value)}
							options={paymentProviders?.map(({ code, name }) => ({ value: code, label: name }))}
						/>
						<CustomSelect
							name="tenure"
							label="Plan tenure"
							placeholder="Select a tenure"
							value={values.tenure}
							defaultValue={values.tenure}
							onValueChange={(value) => setFieldValue("tenure", value)}
							options={returnTenureValues(values.paymentProvider).map((ten) => ({ value: ten, label: ten }))}
						/>
						<CustomSelect
							name="status"
							label="Plan status"
							placeholder="Select a status"
							value={values.status}
							defaultValue={values.status}
							onValueChange={(value) => setFieldValue("status", value)}
							options={["OFFLINE", "ONLINE"].map((ten) => ({ value: ten, label: ten }))}
						/>
						<CustomInput
							value={values.discountApplied}
							onChange={handleChange}
							name="discountApplied"
							inputType="numeric"
							type="number"
							placeholder="Plan discount"
							label="Plan discount(%)"
							innerClassName="!px-6 !py-3"
							className="!text-gray-500 placeholder:text-gray-400"
						/>
					</div>
					<CustomMultiSelect
						name="countriesApplied"
						label="Plan countries"
						placeholder="Select countries"
						defaultValue={values.countriesApplied}
						onValueChange={(value) => setFieldValue("countriesApplied", value)}
						options={countries?.map(({ code, name }) => ({ value: code, label: name }))}
					/>
					<Tiptap onChange={(value) => setFieldValue("description", value)} description={values.description} />
					<div>
						<label className="block text-[1.4rem] text-black font-semibold mt-4">
							Limits <span className="text-[1.2rem] font-normal text-gray">(Number of free calls per month)</span>
						</label>
						<div className="grid gap-6 grid-cols-[repeat(auto-fit,minmax(200px,1fr))]">
							<CustomInput
								value={values.limits.EMAIL}
								onChange={handleChange}
								name="limits.EMAIL"
								inputType="numeric"
								type="number"
								placeholder="Email Limit"
								label="Email"
								innerClassName="!px-6 !py-3"
								className="!text-gray-500 placeholder:text-gray-400"
							/>
							<CustomInput
								value={values.limits.SMS}
								onChange={handleChange}
								name="limits.SMS"
								inputType="numeric"
								type="number"
								placeholder="SMS Limit"
								label="SMS"
								innerClassName="!px-6 !py-3"
								className="!text-gray-500 placeholder:text-gray-400"
							/>
							<CustomInput
								value={values.limits.WHATSAPP}
								onChange={handleChange}
								name="limits.WHATSAPP"
								inputType="numeric"
								type="number"
								placeholder="WhatsApp Limit"
								label="WhatsApp"
								innerClassName="!px-6 !py-3"
								className="!text-gray-500 placeholder:text-gray-400"
							/>
						</div>
					</div>
					<div>
						<label className="block text-[1.4rem] text-black font-semibold mt-4">
							Charges <span className="text-[1.2rem] font-normal text-gray">(Charge per additional call after limit)</span>
						</label>
						<div className="grid gap-6 grid-cols-[repeat(auto-fit,minmax(200px,1fr))]">
							<CustomInput
								value={values.extraCharges.EMAIL}
								onChange={handleChange}
								name="extraCharges.EMAIL"
								inputType="numeric"
								type="number"
								placeholder="Email Charge"
								label="Email"
								innerClassName="!px-6 !py-3"
								className="!text-gray-500 placeholder:text-gray-400"
							/>
							<CustomInput
								value={values.extraCharges.SMS}
								onChange={handleChange}
								name="extraCharges.SMS"
								inputType="numeric"
								type="number"
								placeholder="SMS Charge"
								label="SMS"
								innerClassName="!px-6 !py-3"
								className="!text-gray-500 placeholder:text-gray-400"
							/>
							<CustomInput
								value={values.extraCharges.WHATSAPP}
								onChange={handleChange}
								name="extraCharges.WHATSAPP"
								inputType="numeric"
								type="number"
								placeholder="WhatsApp Charge"
								label="WhatsApp"
								innerClassName="!px-6 !py-3"
								className="!text-gray-500 placeholder:text-gray-400"
							/>
						</div>
					</div>
					<Button className="flex items-center gap-4 rounded-lg text-[1.4rem] px-6 mt-8 py-5" disabled={isLoading}>
						Create plan
					</Button>
				</form>
			)}
		</Formik>
	);
};

const HandleTenureChange = () => {
	const { values, setFieldValue } = useFormikContext();

	useEffect(() => {
		setFieldValue("tenure", "");

		if (values.paymentProvider === "paystack") {
			setFieldValue("tenure_interval", 0);
		} else {
			setFieldValue("tenure_interval", 1);
		}
	}, [setFieldValue, values.paymentProvider]);

	return null;
};

export default CreateSubscription;
