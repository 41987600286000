import Swal from "sweetalert2";
import { ENDPOINTS } from "./constants";

export const renderCurrency = (amount, currency = "NGN", positive = true, showSign = false) => {
	return new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency,
		trailingZeroDisplay: "stripIfInteger",
		currencyDisplay: "narrowSymbol",
		signDisplay: showSign ? "always" : "auto",
	}).format(positive ? parseFloat(amount || 0) : parseFloat(amount || 0) * -1);
};

export const renderNumber = (amount, positive = true, showSign = false) => {
	return new Intl.NumberFormat("en-GB", {
		trailingZeroDisplay: "stripIfInteger",
		currencyDisplay: "narrowSymbol",
		signDisplay: showSign ? "always" : "auto",
	}).format(positive ? parseFloat(amount) : parseFloat(amount) * -1);
};

export const removeItemAtIndex = (array, indexToRemove) => {
	const copy = [...array];
	if (indexToRemove >= 0 && indexToRemove < copy.length) {
		copy.splice(indexToRemove, 1);
	}
	return copy;
};

export const setToStorage = (key, value) => {
	let storedValue = JSON.stringify(value);
	localStorage.setItem(key, storedValue);
};

export const getFromStorage = (key) => {
	let value = localStorage?.getItem(key);
	return value ? JSON?.parse(value) : null;
};

export const removeFromStorage = (key) => {
	localStorage.removeItem(key);
};

export const renderAlert = ({ msg = "", title = "", type = "info", confirmButtonText = "OK", confirmButtonColor = "var(--secondary-color)" }) => {
	return new Promise((resolve) => {
		Swal.fire({
			text: msg,
			title: title,
			icon: type,
			confirmButtonText: confirmButtonText,
			confirmButtonColor: confirmButtonColor,
		}).then((result) => {
			resolve(result);
		});
	});
};

export const renderToastMsg = (message, icon = "error", time = 3000) => {
	return new Promise((resolve) => {
		const Toast = Swal.mixin({
			toast: true,
			position: "top-end",
			showConfirmButton: false,
			timer: time,
			timerProgressBar: true,
			width: "auto",
		});

		Toast.fire({
			icon: icon,
			title: message,
		}).then(() => {
			resolve();
		});
	});
};

export const renderConfirmDialogue = (message = "Are you sure?", text = "You won't be able to revert this!", confirmButtonText = "Yes, go on") => {
	return new Promise((resolve, reject) => {
		Swal.fire({
			title: message,
			text: text,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "var(--primary-color)",
			confirmButtonText: confirmButtonText,
		}).then((result) => {
			if (result.isConfirmed) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	});
};

export const removeFalsyElement = (object) => {
	const newObject = {};
	Object.keys(object).forEach((key) => {
		if (object[key]) {
			newObject[key] = object[key];
		}
	});
	return newObject;
};

export const hasPermission = (permissions) => {
	const userPermissions = getFromStorage("user")?.role?.permissions;

	if (userPermissions?.at(0) === "*") return true;

	if (Array.isArray(permissions)) {
		return permissions.every((permission) => userPermissions?.includes(permission));
	}

	return userPermissions?.includes(permissions);
};

const matchUrlPattern = (urlPattern, url) => {
	const regexPattern = urlPattern.replace(/:[^/]+/g, "[^/]+");
	const regex = new RegExp(`^${regexPattern}$`);
	return regex.test(url);
};

export const removeFirstSubdirectory = (url) => {
	const urlParts = url.split("/").filter((part) => part !== "");

	if (urlParts.length > 0) {
		urlParts.splice(0, 1);
	}

	return "/" + urlParts.join("/");
};

export const getEndpointName = (url, method) => {
	for (let [key, value] of ENDPOINTS.entries()) {
		if (key.method === method && matchUrlPattern(key.url, url.replace(/\/+$/, ""))) {
			return value;
		}
	}
	return null;
};
