import React, { useState } from "react";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { ErrorMessage } from "formik";

const CustomInput = ({ label, type = "text", name, icon, innerClassName, containerClassName, ...props }) => {
	const [inputType, setInputType] = useState(type);

	const togglePasswordVisibility = () => {
		setInputType(inputType === "password" ? "text" : "password");
	};

	return (
		<div className={containerClassName}>
			{label && (
				<label htmlFor={name} className="block text-[1.4rem] font-medium text-gray mb-1">
					{label}
				</label>
			)}
			<div
				className={`flex gap-3 items-center border border-[#D2D5DA] focus-within:border-[#f97316] px-6 py-4 rounded-[6px] ${innerClassName}`}
			>
				{icon}
				<input type={inputType} id={name} name={name} className={`flex-grow rounded-[6px] ${props["className"]}`} {...props} />
				{type === "password" && (
					<div onClick={togglePasswordVisibility} className="cursor-pointer">
						{inputType === "password" ? (
							<PiEyeLight className="text-gray-700 w-[18px] h-[18px]" />
						) : (
							<PiEyeSlashLight className="text-gray-700 w-[18px] h-[18px]" />
						)}
					</div>
				)}
			</div>
			{name && <ErrorMessage name={name} component="div" className="text-red-500 text-[1.2rem] mt-1" />}
		</div>
	);
};

export default CustomInput;
