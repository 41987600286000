import React from "react";

const InfoRow = ({ title, info, infoClassName }) => {
	return (
		<div className="flex items-start justify-between">
			<p className="text-gray-500 text-[1.5rem]">{title}</p>
			<p className={`text-darkBlue text-[1.5rem] max-w-[60%] text-right ${infoClassName}`}>{info}</p>
		</div>
	);
};

export default InfoRow;
