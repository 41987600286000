import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { useModalContext } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select";
import { useToast } from "src/components/ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { changeAdminRole, getAdminRoles } from "src/services/api";
import * as Yup from "yup";

const ChangeStaffRole = ({ row }) => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { setIsOpen } = useModalContext();
	const { toast } = useToast();

	const initialValues = {
		id: row?.id,
		role: "",
	};
	const validationSchema = Yup.object().shape({
		role: Yup.number().integer().required("This field is required"),
	});

	const { data } = useQuery({
		queryKey: ["adminRoles"],
		queryFn: getAdminRoles,
	});
	const roles = data?.data;

	const { mutate, isLoading } = useMutation(changeAdminRole, {
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["admins"] });
			setIsOpen(false);
		},
		onError: (error) => {
			if (error?.cause === 402) {
				setIsOpen(false);
				setIsPasswordModalOpen(true);
			}
		},
	});
	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, setFieldValue, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-10 pt-10">
					<Select onValueChange={(value) => setFieldValue("role", value)} defaultValue={values.role}>
						<SelectTrigger className="text-gray-800">
							<SelectValue placeholder="Select a role" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectLabel>Roles</SelectLabel>
								{roles?.map(({ id, name }, index) => (
									<SelectItem value={String(id)} key={index}>
										{name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
					<Button disabled={isLoading} className="!py-4 !px-10 ml-auto text-[1.4rem] w-fit">
						Save
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default ChangeStaffRole;
