import React from "react";

const InfoColumn = ({ title, info }) => {
	return (
		<div>
			<p className="text-gray-500 text-[1.4rem]">{title}</p>
			<p className="text-darkBlue font-semibold text-[1.6rem]">{info}</p>
		</div>
	);
};

export default InfoColumn;
