import React from "react";
import { Switch } from "src/components/ui/switch";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { COMMS, COUNTRIES_WITH_FLAGS, LOGISTICS, PAYMENT_GATEWAY } from "src/utils/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getSettings, updateSetting } from "src/services/api";
import { useToast } from "src/components/ui/use-toast";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const getFilteredSetting = (constant, apiRes) =>
	constant
		.map((cons) => {
			const correspondingSetting = apiRes.find((s) => s.value.toLowerCase() === cons.name.toLowerCase());
			if (correspondingSetting) {
				return {
					...cons,
					enabled: correspondingSetting.enabled,
					type: correspondingSetting.type,
					value: correspondingSetting.value,
				};
			}
			return null;
		})
		.filter((cons) => cons !== null);

const Configuration = () => {
	return (
		<Tabs>
			<div className="flex items-center justify-between my-[2rem]">
				<TabList>
					<Tab data-id="all-configuration">All Configurations</Tab>
					<Tab data-id="enabled">Enabled</Tab>
					<Tab data-id="disabled">Disabled</Tab>
				</TabList>
			</div>

			<TabPanel>
				<AllConfigurations />
			</TabPanel>
			<TabPanel>
				<EnabledConfigurations />
			</TabPanel>
			<TabPanel>
				<DisabledConfigurations />
			</TabPanel>
		</Tabs>
	);
};

const AllConfigurations = () => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const { mutate } = useMutation(updateSetting, {
		onError: (error) => {
			if (error?.cause === 402) {
				queryClient.refetchQueries({ queryKey: ["settings"] });
				setIsPasswordModalOpen(true);
			}
		},
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["settings"] });
		},
	});

	const { data } = useQuery({
		queryKey: ["settings"],
		queryFn: () => getSettings(),
		suspense: true,
	});
	const settings = data?.data;

	const paymentGateway = settings?.find((setting) => setting?.type === "paymentProvider")?.setting;
	const logistics = settings?.find((setting) => setting?.type === "logistics")?.setting;
	const countries = settings?.find((setting) => setting?.type === "country")?.setting;
	const comms = settings?.find((setting) => setting?.type === "communication")?.setting;

	const filteredLogistics = getFilteredSetting(LOGISTICS, logistics);
	const filteredPaymentGateWay = getFilteredSetting(PAYMENT_GATEWAY, paymentGateway);
	const filteredCountries = getFilteredSetting(COUNTRIES_WITH_FLAGS, countries);
	const filteredComms = getFilteredSetting(COMMS, comms);

	return (
		<>
			<div className="mb-[6rem]">
				<p className="text-[1.8rem] font-bold">Payment Integrations</p>
				<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
					Effortlessly accept payments through various methods, ensuring a smooth and secure experience.
				</p>

				<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fit,minmax(400px,1fr))]`}>
					{filteredPaymentGateWay?.map((payment, i) => (
						<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
							<div className="flex items-center justify-between ">
								<div className="flex justify-between items-center gap-[1rem] flex-wrap">
									<div className="border flex justify-center items-center p-[1rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
										{payment?.icon}
									</div>
									<p className="text-[1.6rem] font-semibold capitalize">{payment?.name}</p>
								</div>
								<Switch
									className="data-[state=checked]:bg-secondary"
									defaultChecked={payment.enabled}
									onCheckedChange={(checked) => mutate({ type: payment.type, value: payment.value, status: Number(checked) })}
								/>
							</div>
							<p className="text-[1.6rem] font-normal text-gray my-[1.5rem]">{payment?.note}</p>
						</div>
					))}
				</div>
			</div>
			<div className="mb-[8rem]">
				<p className="text-[1.8rem] font-bold">Logistics</p>
				<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
					Streamline your shipping and fulfillment processes with seamless integrations to top logistics providers.
				</p>

				<div
					className={`my-[3rem] grid gap-[3rem] ${
						filteredLogistics.length === 1
							? "grid-cols-1"
							: filteredLogistics.length === 2
							? "grid-cols-2"
							: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
					}`}
				>
					{filteredLogistics?.map((log, i) => (
						<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
							<div className="flex items-center justify-between ">
								<div className="flex justify-between items-center gap-[1rem] flex-wrap">
									<div className="border flex justify-center items-center p-[1rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
										{log?.icon}
									</div>
									<p className="text-[1.6rem] font-semibold">{log?.name}</p>
								</div>
								<Switch
									disabled
									className="data-[state=checked]:bg-secondary"
									defaultChecked={log.enabled}
									onCheckedChange={(checked) => mutate({ type: log.type, value: log.value, status: Number(checked) })}
								/>
							</div>
							<p className="text-[1.6rem] font-normal text-gray my-[1.5rem]">{log?.note}</p>
						</div>
					))}
				</div>
			</div>
			<div className="mb-[8rem]">
				<p className="text-[1.8rem] font-bold">Exemption/ allow list (allowed regions and currencies)</p>
				<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
					Easily manage and restrict access to specific regions and currencies, tailoring your store's reach to your business needs
				</p>

				<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(265px,1fr))]`}>
					{filteredCountries?.map((country, i) => (
						<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
							<div className="flex items-center justify-between ">
								<div className="flex justify-between items-center gap-[1rem] flex-wrap">
									<div className="border flex overflow-hidden justify-center items-center p-[0.5rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
										<img className="w-full h-full" alt={country.countryName} src={country.flag} />
									</div>
									<p className="text-[1.6rem] font-semibold">{country?.countryName}</p>
								</div>
								<Switch
									// disable config for other countries
									disabled={country.value !== "NG"}
									className="data-[state=checked]:bg-secondary"
									defaultChecked={country.enabled}
									onCheckedChange={(checked) => mutate({ type: country.type, value: country.value, status: Number(checked) })}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="mb-[8rem]">
				<p className="text-[1.8rem] font-bold">Communication</p>
				<p className="text-[1.6rem] my-[1rem] text-gray font-normal">Easily manage and restrict access to the communication co-pilot</p>

				<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(265px,1fr))]`}>
					{filteredComms?.map((comms, i) => (
						<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
							<div className="flex items-center justify-between ">
								<div className="flex justify-between items-center gap-[1rem] flex-wrap">
									<div className="border flex justify-center items-center p-[0.5rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
										{comms?.icon}
									</div>
									<p className="text-[1.6rem] font-semibold">{comms?.name}</p>
								</div>
								<Switch
									className="data-[state=checked]:bg-secondary"
									defaultChecked={comms.enabled}
									onCheckedChange={(checked) => mutate({ type: comms.type, value: comms.value, status: Number(checked) })}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

const EnabledConfigurations = () => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const { mutate } = useMutation(updateSetting, {
		onError: (error) => {
			if (error?.cause === 402) {
				queryClient.refetchQueries({ queryKey: ["enabled-settings"] });
				setIsPasswordModalOpen(true);
			}
		},
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["enabled-settings"] });
		},
	});

	const { data } = useQuery({
		queryKey: ["enabled-settings"],
		queryFn: () => getSettings({ status: 1 }),
		suspense: true,
	});
	const settings = data?.data;

	const paymentGateway = settings?.find((setting) => setting?.type === "paymentProvider")?.setting;
	const logistics = settings?.find((setting) => setting?.type === "logistics")?.setting;
	const countries = settings?.find((setting) => setting?.type === "country")?.setting;
	const comms = settings?.find((setting) => setting?.type === "communication")?.setting;

	const filteredLogistics = getFilteredSetting(LOGISTICS, logistics);
	const filteredPaymentGateWay = getFilteredSetting(PAYMENT_GATEWAY, paymentGateway);
	const filteredCountries = getFilteredSetting(COUNTRIES_WITH_FLAGS, countries);
	const filteredComms = getFilteredSetting(COMMS, comms);

	return (
		<>
			{!!filteredPaymentGateWay.length && (
				<div className="mb-[6rem]">
					<p className="text-[1.8rem] font-bold">Payment Integrations</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
						Effortlessly accept payments through various methods, ensuring a smooth and secure experience.
					</p>

					<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fit,minmax(400px,1fr))]`}>
						{filteredPaymentGateWay?.map((payment, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex justify-center items-center p-[1rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											{payment?.icon}
										</div>
										<p className="text-[1.6rem] font-semibold capitalize">{payment?.name}</p>
									</div>
									<Switch
										className="data-[state=checked]:bg-secondary"
										defaultChecked={payment.enabled}
										onCheckedChange={(checked) => mutate({ type: payment.type, value: payment.value, status: Number(checked) })}
									/>
								</div>
								<p className="text-[1.6rem] font-normal text-gray my-[1.5rem]">{payment?.note}</p>
							</div>
						))}
					</div>
				</div>
			)}
			{!!filteredLogistics.length && (
				<div className="mb-[8rem]">
					<p className="text-[1.8rem] font-bold">Logistics</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
						Streamline your shipping and fulfillment processes with seamless integrations to top logistics providers.
					</p>

					<div
						className={`my-[3rem] grid gap-[3rem] ${
							filteredLogistics.length === 1
								? "grid-cols-1"
								: filteredLogistics.length === 2
								? "grid-cols-2"
								: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
						}`}
					>
						{filteredLogistics?.map((log, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex justify-center items-center p-[1rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											{log?.icon}
										</div>
										<p className="text-[1.6rem] font-semibold">{log?.name}</p>
									</div>
									<Switch
										disabled
										className="data-[state=checked]:bg-secondary"
										defaultChecked={log.enabled}
										onCheckedChange={(checked) => mutate({ type: log.type, value: log.value, status: Number(checked) })}
									/>
								</div>
								<p className="text-[1.6rem] font-normal text-gray my-[1.5rem]">{log?.note}</p>
							</div>
						))}
					</div>
				</div>
			)}
			{!!filteredCountries.length && (
				<div className="mb-[8rem]">
					<p className="text-[1.8rem] font-bold">Exemption/ allow list (allowed regions and currencies)</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
						Easily manage and restrict access to specific regions and currencies, tailoring your store's reach to your business needs
					</p>

					<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(265px,1fr))]`}>
						{filteredCountries?.map((country, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex overflow-hidden justify-center items-center p-[0.5rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											<img className="w-full h-full" alt={country.countryName} src={country.flag} />
										</div>
										<p className="text-[1.6rem] font-semibold">{country?.countryName}</p>
									</div>
									<Switch
										// disable config for other countries
										disabled={country.value !== "NG"}
										className="data-[state=checked]:bg-secondary"
										defaultChecked={country.enabled}
										onCheckedChange={(checked) => mutate({ type: country.type, value: country.value, status: Number(checked) })}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			{!!filteredComms.length && (
				<div className="mb-[8rem]">
					<p className="text-[1.8rem] font-bold">Communication</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">Easily manage and restrict access to the communication co-pilot</p>

					<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(265px,1fr))]`}>
						{filteredComms?.map((comms, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex justify-center items-center p-[0.5rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											{comms?.icon}
										</div>
										<p className="text-[1.6rem] font-semibold">{comms?.name}</p>
									</div>
									<Switch
										className="data-[state=checked]:bg-secondary"
										defaultChecked={comms.enabled}
										onCheckedChange={(checked) => mutate({ type: comms.type, value: comms.value, status: Number(checked) })}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

const DisabledConfigurations = () => {
	const { setIsPasswordModalOpen } = useProtectedRoutesContext();
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const { mutate } = useMutation(updateSetting, {
		onError: (error) => {
			if (error?.cause === 402) {
				queryClient.refetchQueries({ queryKey: ["disabled-settings"] });
				setIsPasswordModalOpen(true);
			}
		},
		onSuccess: ({ message }) => {
			toast({ title: "Success", description: message });
			queryClient.refetchQueries({ queryKey: ["disabled-settings"] });
		},
	});

	const { data } = useQuery({
		queryKey: ["disabled-settings"],
		queryFn: () => getSettings({ status: "0" }),
		suspense: true,
	});
	const settings = data?.data;

	const paymentGateway = settings?.find((setting) => setting?.type === "paymentProvider")?.setting;
	const logistics = settings?.find((setting) => setting?.type === "logistics")?.setting;
	const countries = settings?.find((setting) => setting?.type === "country")?.setting;
	const comms = settings?.find((setting) => setting?.type === "communication")?.setting;

	const filteredLogistics = getFilteredSetting(LOGISTICS, logistics);
	const filteredPaymentGateWay = getFilteredSetting(PAYMENT_GATEWAY, paymentGateway);
	const filteredCountries = getFilteredSetting(COUNTRIES_WITH_FLAGS, countries);
	const filteredComms = getFilteredSetting(COMMS, comms);

	return (
		<>
			{!!filteredPaymentGateWay.length && (
				<div className="mb-[6rem]">
					<p className="text-[1.8rem] font-bold">Payment Integrations</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
						Effortlessly accept payments through various methods, ensuring a smooth and secure experience.
					</p>

					<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fit,minmax(400px,1fr))]`}>
						{filteredPaymentGateWay?.map((payment, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex justify-center items-center p-[1rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											{payment?.icon}
										</div>
										<p className="text-[1.6rem] font-semibold capitalize">{payment?.name}</p>
									</div>
									<Switch
										className="data-[state=checked]:bg-secondary"
										defaultChecked={payment.enabled}
										onCheckedChange={(checked) => mutate({ type: payment.type, value: payment.value, status: Number(checked) })}
									/>
								</div>
								<p className="text-[1.6rem] font-normal text-gray my-[1.5rem]">{payment?.note}</p>
							</div>
						))}
					</div>
				</div>
			)}
			{!!filteredLogistics.length && (
				<div className="mb-[8rem]">
					<p className="text-[1.8rem] font-bold">Logistics</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
						Streamline your shipping and fulfillment processes with seamless integrations to top logistics providers.
					</p>

					<div
						className={`my-[3rem] grid gap-[3rem] ${
							filteredLogistics.length === 1
								? "grid-cols-1"
								: filteredLogistics.length === 2
								? "grid-cols-2"
								: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
						}`}
					>
						{filteredLogistics?.map((log, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex justify-center items-center p-[1rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											{log?.icon}
										</div>
										<p className="text-[1.6rem] font-semibold">{log?.name}</p>
									</div>
									<Switch
										disabled
										className="data-[state=checked]:bg-secondary"
										defaultChecked={log.enabled}
										onCheckedChange={(checked) => mutate({ type: log.type, value: log.value, status: Number(checked) })}
									/>
								</div>
								<p className="text-[1.6rem] font-normal text-gray my-[1.5rem]">{log?.note}</p>
							</div>
						))}
					</div>
				</div>
			)}
			{!!filteredCountries.length && (
				<div className="mb-[8rem]">
					<p className="text-[1.8rem] font-bold">Exemption/ allow list (allowed regions and currencies)</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">
						Easily manage and restrict access to specific regions and currencies, tailoring your store's reach to your business needs
					</p>

					<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(265px,1fr))]`}>
						{filteredCountries?.map((country, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex overflow-hidden justify-center items-center p-[0.5rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											<img className="w-full h-full" alt={country.countryName} src={country.flag} />
										</div>
										<p className="text-[1.6rem] font-semibold">{country?.countryName}</p>
									</div>
									<Switch
										// disable config for other countries
										disabled={country.value !== "NG"}
										className="data-[state=checked]:bg-secondary"
										defaultChecked={country.enabled}
										onCheckedChange={(checked) => mutate({ type: country.type, value: country.value, status: Number(checked) })}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			{!!filteredComms.length && (
				<div className="mb-[8rem]">
					<p className="text-[1.8rem] font-bold">Communication</p>
					<p className="text-[1.6rem] my-[1rem] text-gray font-normal">Easily manage and restrict access to the communication co-pilot</p>

					<div className={`my-[3rem] grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(265px,1fr))]`}>
						{filteredComms?.map((comms, i) => (
							<div key={i} className="border border-[#E5E7EB] p-[2.4rem] rounded-[.6rem]">
								<div className="flex items-center justify-between ">
									<div className="flex justify-between items-center gap-[1rem] flex-wrap">
										<div className="border flex justify-center items-center p-[0.5rem] h-[4rem] w-[4rem] bg-[#F9FAFB] rounded-[1rem]">
											{comms?.icon}
										</div>
										<p className="text-[1.6rem] font-semibold">{comms?.name}</p>
									</div>
									<Switch
										className="data-[state=checked]:bg-secondary"
										defaultChecked={comms.enabled}
										onCheckedChange={(checked) => mutate({ type: comms.type, value: comms.value, status: Number(checked) })}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default Configuration;
