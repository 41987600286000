import React from "react";
import { Formik } from "formik";
import { CustomInput } from "src/components";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { renderAlert } from "src/utils/functions";
import { forgotPassword } from "src/services/AuthAPI";

const ForgotPassword = ({ setIsModalOpen }) => {
	const validationSchema = yup.object({
		email: yup.string().email("Invalid email address").required("Email is required"),
	});

	const { mutate, isLoading } = useMutation(forgotPassword, {
		onSuccess: (res) => {
			setIsModalOpen(false);
			renderAlert({
				title: "Success",
				msg: res?.message,
				type: "success",
			});
		},
	});

	return (
		<div>
			<Formik
				initialValues={{ email: "" }}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					mutate(values);
				}}
			>
				{({ values, handleChange, handleBlur, handleSubmit }) => (
					<form onSubmit={handleSubmit} className="my-[1rem]">
						<div className="">
							<CustomInput name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} placeholder="Email" />
						</div>

						<button
							type="submit"
							disabled={isLoading}
							className="btn-loader bg-primary w-full text-white rounded-[6px] h-[4.4rem] py-[.8rem] px-[2rem] mt-[3rem]"
						>
							Submit
						</button>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default ForgotPassword;
